@media screen {
  body.print {
    background: #fff;
    position: relative;
    overflow: hidden;
  }
    body.print > * {
      display: none;
    }

    body.print:before {
      content: "Подождите, сейчас откроется окно печати страницы...\AЕсли этого не произошло, просьба распечатать страницу вручную";
      color: #333;
      white-space: pre-wrap;
      width: 100%;
    }
}

@media print {
  .content {
    padding: 0px 0px 15px;
  }


  .wrapper {
    width: 100%;
  }


  .header-phones + div,
  .header-menu-container,
  .product-page-buttons,
  a.zoom-image:before,
  .hr:before {
    display: none;
  }

  .hr {
    padding: 15px 0px 0px;
  }


  .header-content {
    padding: 0px;
    box-shadow: none;
  }
    .header-phones a {
      white-space: nowrap;
    }


  .content-inner,
  .section-inner {
    padding-top: 15px;
  }


  .subheader {
    font-size: 1.8rem;
  }
  .subsubheader {
    font-size: 1.6rem;
  }


  .product-page-left {
    min-width: 45%;
  }
    .product-page-gallery {
      height: auto;
      padding: 0px;
    }
      .product-page-gallery-image {
        position: relative;
      }
        .product-page-gallery-image img {
          width: 100%;
        }

  .product-page-left + div {
    padding-left: 3%;
  }
    .product-page-info-wrapper .product-page-params .subheader {
      font-weight: 400;
    }

    .product-page-info-wrapper .product-page-params table tr > th {
      width: auto;
    }


  .object-map-container {
    margin: 0px;
  }
    .object-map-container:before {
      padding-top: 45%;
    }
    .object-map-container > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
      .object-map-container > div img {
        width: 100%;
      }


  .footer {
    background-color: transparent;
  }
    .footer-content {
      padding: 15px 0px;
    }
      .footer-info-container .info-header,
      .footer-info-container .info-header ~ div * {
        color: #333;
      }

      .footer .address-icon-container,
      .footer .phones-icon-container,
      .footer .worktime-icon-container,
      .footer .emails-icon-container {
        padding-left: 140px;
      }
        .footer .address-icon-container:before,
        .footer .phones-icon-container:before,
        .footer .worktime-icon-container:before,
        .footer .emails-icon-container:before {
          background: none !important;
          font-weight: 600;
          width: auto;
          height: auto;
          top: auto;
          white-space: nowrap;
        }
        .footer .address-icon-container:before {
          content: "Адрес: ";
        }
        .footer .phones-icon-container:before {
          content: "Телефоны: ";
        }
        .footer .worktime-icon-container:before {
          content: "Режим работы: ";
        }
        .footer .emails-icon-container:before {
          content: "E-mail: ";
        }
}