@media screen and (max-width: 1223px) {
  .wrapper {
    width: 100%;
    padding: 0px 10px;
  }
    .unwrapper {
      margin-left: -10px;
      margin-right: -10px;
    }

  .info-page-image { width: 27%; }

  .gallery-item.gallery-item-width-1 { width: 100%; }
  .gallery-item.gallery-item-width-2 { width: 50%; }
  .gallery-item.gallery-item-width-3 { width: 33.33%; }
  .gallery-item.gallery-item-width-4 { width: 25%; }
    .gallery-item img { width: 100%; }
}

@media screen and (max-width: 1023px) {
  .t_dn { display: none; }
  .t_dn_i { display: none !important; }
  .t_db { display: block; }
  .t_dib { display: inline-block; }
  .t_dt { display: table; }
  .t_dtr { display: table-row; }
  .t_dtc { display: table-cell; }
  /* Flex */
  .t_dfdr { display: flex; flex-direction: row; }
  .t_dfdrr { display: flex; flex-direction: row-reverse; }
  .t_dfdc { display: flex; flex-direction: column; }
  .t_dfdcr { display: flex; flex-direction: column-reverse; }
  .t_fwn { flex-wrap: nowrap; }
  .t_fww { flex-wrap: wrap; }
  .t_fwwr { flex-wrap: wrap-reverse; }
  .t_fjcs { justify-content: flex-start; }
  .t_fjce { justify-content: flex-end; }
  .t_fjcc { justify-content: center; }
  .t_fjcsa { justify-content: space-around; }
  .t_fjcsb { justify-content: space-between; }
  .t_fais { align-items: flex-start; }
  .t_faie { align-items: flex-end; }
  .t_faic { align-items: center; }
  .t_faib { align-items: baseline; }
  .t_faist { align-items: stretch; }

  .t_h0 { height: 0px; }
  .t_hfull { height: 100%; }
  .t_hauto { height: auto; }

  .t_w0 { width: 0px; }
  .t_w1 { width: 1px; }
  .t_w10p { width: 10%; }
  .t_w12p { width: 12.5%; }
  .t_w15p { width: 15%; }
  .t_w20p { width: 20%; }
  .t_w25p { width: 25%; }
  .t_w33p { width: 33.3333%; }
  .t_w35p { width: 35%; }
  .t_w40p { width: 40%; }
  .t_w50p { width: 50%; }
  .t_w60p { width: 60%; }
  .t_w65p { width: 65%; }
  .t_w66p { width: 66.6666%; }
  .t_w70p { width: 70%; }
  .t_w75p { width: 75%; }
  .t_wfull { width: 100%; }
  .t_wauto { width: auto; }

  .t_m0a { margin: 0 auto; }
  .t_m0a_i { margin: 0 auto !important; }
  .t_ma0 { margin: auto 0; }
  .t_ma0_i { margin: auto 0 !important; }
  .t_ma { margin: auto; }
  .t_ma_i { margin: auto !important; }
  .t_mta { margin-top: auto; }
  .t_mta_i { margin-top: auto !important; }
  .t_mla { margin-left: auto; }
  .t_mla_i { margin-left: auto !important; }
  .t_mra { margin-right: auto; }
  .t_mra_i { margin-right: auto !important; }
  .t_mba { margin-bottom: auto; }
  .t_mba_i { margin-bottom: auto !important; }
  .t_m0 { margin: 0; }
  .t_m0_i { margin: 0 !important; }
  .t_mt0 { margin-top: 0; }
  .t_mt0_i { margin-top: 0 !important; }
  .t_ml0 { margin-left: 0; }
  .t_ml0_i { margin-left: 0 !important; }
  .t_mr0 { margin-right: 0; }
  .t_mr0_i { margin-right: 0 !important; }
  .t_mb0 { margin-bottom: 0; }
  .t_mb0_i { margin-bottom: 0 !important; }
  .t_m1 { margin: 1px; }
  .t_mt1 { margin-top: 1px; }
  .t_ml1 { margin-left: 1px; }
  .t_mr1 { margin-right: 1px; }
  .t_mb1 { margin-bottom: 1px; }
  .t_m2 { margin: 2px; }
  .t_mt2 { margin-top: 2px; }
  .t_ml2 { margin-left: 2px; }
  .t_mr2 { margin-right: 2px; }
  .t_mb2 { margin-bottom: 2px; }
  .t_m3 { margin: 3px; }
  .t_mt3 { margin-top: 3px; }
  .t_ml3 { margin-left: 3px; }
  .t_mr3 { margin-right: 3px; }
  .t_mb3 { margin-bottom: 3px; }
  .t_m4 { margin: 4px; }
  .t_mt4 { margin-top: 4px; }
  .t_ml4 { margin-left: 4px; }
  .t_mr4 { margin-right: 4px; }
  .t_mb4 { margin-bottom: 4px; }
  .t_m5 { margin: 5px; }
  .t_mt5 { margin-top: 5px; }
  .t_ml5 { margin-left: 5px; }
  .t_mr5 { margin-right: 5px; }
  .t_mb5 { margin-bottom: 5px; }
  .t_m7 { margin: 7px; }
  .t_mt7 { margin-top: 7px; }
  .t_ml7 { margin-left: 7px; }
  .t_mr7 { margin-right: 7px; }
  .t_mb7 { margin-bottom: 7px; }
  .t_m10 { margin: 10px; }
  .t_mt10 { margin-top: 10px; }
  .t_ml10 { margin-left: 10px; }
  .t_mr10 { margin-right: 10px; }
  .t_mb10 { margin-bottom: 10px; }
  .t_m13 { margin: 13px; }
  .t_mt13 { margin-top: 13px; }
  .t_ml13 { margin-left: 13px; }
  .t_mr13 { margin-right: 13px; }
  .t_mb13 { margin-bottom: 13px; }
  .t_m15 { margin: 15px; }
  .t_mt15 { margin-top: 15px; }
  .t_ml15 { margin-left: 15px; }
  .t_mr15 { margin-right: 15px; }
  .t_mb15 { margin-bottom: 15px; }
  .t_m20 { margin: 20px; }
  .t_mt20 { margin-top: 20px; }
  .t_ml20 { margin-left: 20px; }
  .t_mr20 { margin-right: 20px; }
  .t_mb20 { margin-bottom: 20px; }
  .t_m-1 { margin: -1px; }
  .t_mt-1 { margin-top: -1px; }
  .t_ml-1 { margin-left: -1px; }
  .t_mr-1 { margin-right: -1px; }
  .t_mb-1 { margin-bottom: -1px; }
  .t_m-2 { margin: -2px; }
  .t_mt-2 { margin-top: -2px; }
  .t_ml-2 { margin-left: -2px; }
  .t_mr-2 { margin-right: -2px; }
  .t_mb-2 { margin-bottom: -2px; }
  .t_m-3 { margin: -3px; }
  .t_mt-3 { margin-top: -3px; }
  .t_ml-3 { margin-left: -3px; }
  .t_mr-3 { margin-right: -3px; }
  .t_mb-3 { margin-bottom: -3px; }
  .t_m-4 { margin: -4px; }
  .t_mt-4 { margin-top: -4px; }
  .t_ml-4 { margin-left: -4px; }
  .t_mr-4 { margin-right: -4px; }
  .t_mb-4 { margin-bottom: -4px; }
  .t_m-5 { margin: -5px; }
  .t_mt-5 { margin-top: -5px; }
  .t_ml-5 { margin-left: -5px; }
  .t_mr-5 { margin-right: -5px; }
  .t_mb-5 { margin-bottom: -5px; }
  .t_m-7 { margin: -7px; }
  .t_mt-7 { margin-top: -7px; }
  .t_ml-7 { margin-left: -7px; }
  .t_mr-7 { margin-right: -7px; }
  .t_mb-7 { margin-bottom: -7px; }
  .t_m-10 { margin: -10px; }
  .t_mt-10 { margin-top: -10px; }
  .t_ml-10 { margin-left: -10px; }
  .t_mr-10 { margin-right: -10px; }
  .t_mb-10 { margin-bottom: -10px; }
  .t_m-13 { margin: -13px; }
  .t_mt-13 { margin-top: -13px; }
  .t_ml-13 { margin-left: -13px; }
  .t_mr-13 { margin-right: -13px; }
  .t_mb-13 { margin-bottom: -13px; }
  .t_m-15 { margin: -15px; }
  .t_mt-15 { margin-top: -15px; }
  .t_ml-15 { margin-left: -15px; }
  .t_mr-15 { margin-right: -15px; }
  .t_mb-15 { margin-bottom: -15px; }
  .t_m-20 { margin: -20px; }
  .t_mt-20 { margin-top: -20px; }
  .t_ml-20 { margin-left: -20px; }
  .t_mr-20 { margin-right: -20px; }
  .t_mb-20 { margin-bottom: -20px; }
  .t_m-25 { margin: -25px; }
  .t_mt-25 { margin-top: -25px; }
  .t_ml-25 { margin-left: -25px; }
  .t_mr-25 { margin-right: -25px; }
  .t_mb-25 { margin-bottom: -25px; }
  .t_m-30 { margin: -30px; }
  .t_mt-30 { margin-top: -30px; }
  .t_ml-30 { margin-left: -30px; }
  .t_mr-30 { margin-right: -30px; }
  .t_mb-30 { margin-bottom: -30px; }

  .t_p0 { padding: 0 !important; }
  .t_pt0 { padding-top: 0 !important; }
  .t_pl0 { padding-left: 0 !important; }
  .t_pr0 { padding-right: 0 !important; }
  .t_pb0 { padding-bottom: 0 !important; }
  .t_p1 { padding: 1px !important; }
  .t_pt1 { padding-top: 1px !important; }
  .t_pl1 { padding-left: 1px !important; }
  .t_pr1 { padding-right: 1px !important; }
  .t_pb1 { padding-bottom: 1px !important; }
  .t_p2 { padding: 2px !important; }
  .t_pt2 { padding-top: 2px !important; }
  .t_pl2 { padding-left: 2px !important; }
  .t_pr2 { padding-right: 2px !important; }
  .t_pb2 { padding-bottom: 2px !important; }
  .t_p3 { padding: 3px !important; }
  .t_pt3 { padding-top: 3px !important; }
  .t_pl3 { padding-left: 3px !important; }
  .t_pr3 { padding-right: 3px !important; }
  .t_pb3 { padding-bottom: 3px !important; }
  .t_p4 { padding: 4px !important; }
  .t_pt4 { padding-top: 4px !important; }
  .t_pl4 { padding-left: 4px !important; }
  .t_pr4 { padding-right: 4px !important; }
  .t_pb4 { padding-bottom: 4px !important; }
  .t_p5 { padding: 5px !important; }
  .t_pt5 { padding-top: 5px !important; }
  .t_pl5 { padding-left: 5px !important; }
  .t_pr5 { padding-right: 5px !important; }
  .t_pb5 { padding-bottom: 5px !important; }
  .t_p7 { padding: 7px !important; }
  .t_pt7 { padding-top: 7px !important; }
  .t_pl7 { padding-left: 7px !important; }
  .t_pr7 { padding-right: 7px !important; }
  .t_pb7 { padding-bottom: 7px !important; }
  .t_p10 { padding: 10px !important; }
  .t_pt10 { padding-top: 10px !important; }
  .t_pl10 { padding-left: 10px !important; }
  .t_pr10 { padding-right: 10px !important; }
  .t_pb10 { padding-bottom: 10px !important; }
  .t_p13 { padding: 13px !important; }
  .t_pt13 { padding-top: 13px !important; }
  .t_pl13 { padding-left: 13px !important; }
  .t_pr13 { padding-right: 13px !important; }
  .t_pb13 { padding-bottom: 13px !important; }
  .t_p15 { padding: 15px !important; }
  .t_pt15 { padding-top: 15px !important; }
  .t_pl15 { padding-left: 15px !important; }
  .t_pr15 { padding-right: 15px !important; }
  .t_pb15 { padding-bottom: 15px !important; }
  .t_p20 { padding: 20px !important; }
  .t_pt20 { padding-top: 20px !important; }
  .t_pl20 { padding-left: 20px !important; }
  .t_pr20 { padding-right: 20px !important; }
  .t_pb20 { padding-bottom: 20px !important; }
  .t_p25 { padding: 25px !important; }
  .t_pt25 { padding-top: 25px !important; }
  .t_pl25 { padding-left: 25px !important; }
  .t_pr25 { padding-right: 25px !important; }
  .t_pb25 { padding-bottom: 25px !important; }
  .t_p30 { padding: 30px !important; }
  .t_pt30 { padding-top: 30px !important; }
  .t_pl30 { padding-left: 30px !important; }
  .t_pr30 { padding-right: 30px !important; }
  .t_pb30 { padding-bottom: 30px !important; }

  .t_fw800, .t_fw800 *, .t_black, .t_black * { font-weight: 800; }
  .t_fw700, .t_fw700 *, .t_bold, .t_bold * { font-weight: 700; }
  .t_fw600, .t_fw600 *, .t_semibold, .t_semibold * { font-weight: 600; }
  .t_fw400, .t_fw400 * { font-weight: 400; }
  .t_fw300, .t_fw300 *, .t_semilight, .t_semilight * { font-weight: 300; }

  .t_tal { text-align: left; }
  .t_tac { text-align: center; }
  .t_tar { text-align: right; }

  .t_vat { vertical-align: top; }
  .t_vam { vertical-align: middle; }
  .t_vab { vertical-align: bottom; }
  .t_vabl { vertical-align: baseline; }

  .t_fz0 { font-size: 0; }
  .t_fz0_i { font-size: 0 !important; }
  .t_fz10, .t_fz10_a, .t_fz10_a * { font-size: 10px; }
  .t_fz10_i, .t_fz10_a_i, .t_fz10_a_i * { font-size: 10px !important; }
  .t_fz11, .t_fz11_a, .t_fz11_a * { font-size: 11px; }
  .t_fz11_i, .t_fz11_a_i, .t_fz11_a_i * { font-size: 11px !important; }
  .t_fz12, .t_fz12_a, .t_fz12_a * { font-size: 12px; }
  .t_fz12_i, .t_fz12_a_i, .t_fz12_a_i * { font-size: 12px !important; }
  .t_fz14, .t_fz14_a, .t_fz14_a * { font-size: 14px; }
  .t_fz14_i, .t_fz14_a_i, .t_fz14_a_i * { font-size: 14px !important; }
  .t_fz15 { font-size: 15px; }
  .t_fz15_i { font-size: 15px !important; }
  .t_fz16 { font-size: 16px; }
  .t_fz16_i { font-size: 16px !important; }
  .t_fz18 { font-size: 18px; }
  .t_fz18_i { font-size: 18px !important; }
  .t_fz20 { font-size: 20px; }
  .t_fz20_i { font-size: 20px !important; }
  .t_fz22 { font-size: 22px; }
  .t_fz22_i { font-size: 22px !important; }
  .t_fz24 { font-size: 24px; }
  .t_fz24_i { font-size: 24px !important; }
  .t_fz26 { font-size: 26px; }
  .t_fz26_i { font-size: 26px !important; }
  .t_fz28 { font-size: 28px; }
  .t_fz28_i { font-size: 28px !important; }
  .t_fz30 { font-size: 30px; }
  .t_fz30_i { font-size: 30px !important; }
  .t_fsi { font-style: italic; }

  .t_fln { float: none; }
  .t_fll { float: left; }
  .t_flr { float: right; }


  html {
    font-size: 13px;
  }


  .button-style {
    font-size: 1.1533rem;
  }


  .content {
    padding: 0% 0% 5%;
  }
    .content-inner,
    .section-inner {
      padding-top: 4%;
    }


    .main-slide-code-container > div {
      padding: 0px 10px;
    }
      .main-slide-code-container > div > div {
        /*width: 50%;*/
        max-width: none;
        margin: 0px 0px 10px;
      }


    .subheader {
      font-size: 2.4615rem;
    }
      .subsubheader {
        font-size: 1.6153rem;
      }


    .gallery-block-container > div {
      margin: -10px 0px 0px -10px;
    }
      .gallery-block-container .gallery-item {
        padding: 10px 0px 0px 10px;
      }


    .advantages-block-container .list-view-item {
      width: 33.33333333%;
    }


    .filter-dropdown-container .filter-dropdown .filter-checkbox-list > div label span {
      width: 18px;
      min-width: 18px;
      height: 18px;
    }
}

@media screen and (max-width: 979px) {
  .banks-block-container .list-view {
    margin-left: -30px;
  }
    .banks-block-container .list-view-item {
      padding-left: 30px;
    }
      .banks-block-container .list-view-item-image {
        margin: 0px 5px;
      }
}

@media screen and (max-width: 899px) {
  .header-social-container {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .st_dn { display: none; }
  .st_dn_i { display: none !important; }
  .st_db { display: block; }
  .st_dib { display: inline-block; }
  .st_dt { display: table; }
  .st_dtr { display: table-row; }
  .st_dtc { display: table-cell; }
  /* Flex */
  .st_dfdr { display: flex; flex-direction: row; }
  .st_dfdrr { display: flex; flex-direction: row-reverse; }
  .st_dfdc { display: flex; flex-direction: column; }
  .st_dfdcr { display: flex; flex-direction: column-reverse; }
  .st_fwn { flex-wrap: nowrap; }
  .st_fww { flex-wrap: wrap; }
  .st_fwwr { flex-wrap: wrap-reverse; }
  .st_fjcs { justify-content: flex-start; }
  .st_fjce { justify-content: flex-end; }
  .st_fjcc { justify-content: center; }
  .st_fjcsa { justify-content: space-around; }
  .st_fjcsb { justify-content: space-between; }
  .st_fais { align-items: flex-start; }
  .st_faie { align-items: flex-end; }
  .st_faic { align-items: center; }
  .st_faib { align-items: baseline; }
  .st_faist { align-items: stretch; }

  .st_h0 { height: 0px; }
  .st_hfull { height: 100%; }
  .st_hauto { height: auto; }

  .st_w0 { width: 0px; }
  .st_w1 { width: 1px; }
  .st_w10p { width: 10%; }
  .st_w12p { width: 12.5%; }
  .st_w15p { width: 15%; }
  .st_w20p { width: 20%; }
  .st_w25p { width: 25%; }
  .st_w33p { width: 33.3333%; }
  .st_w35p { width: 35%; }
  .st_w40p { width: 40%; }
  .st_w50p { width: 50%; }
  .st_w60p { width: 60%; }
  .st_w65p { width: 65%; }
  .st_w66p { width: 66.6666%; }
  .st_w70p { width: 70%; }
  .st_w75p { width: 75%; }
  .st_wfull { width: 100%; }
  .st_wauto { width: auto; }

  .st_m0a { margin: 0 auto; }
  .st_m0a_i { margin: 0 auto !important; }
  .st_ma0 { margin: auto 0; }
  .st_ma0_i { margin: auto 0 !important; }
  .st_ma { margin: auto; }
  .st_ma_i { margin: auto !important; }
  .st_mta { margin-top: auto; }
  .st_mta_i { margin-top: auto !important; }
  .st_mla { margin-left: auto; }
  .st_mla_i { margin-left: auto !important; }
  .st_mra { margin-right: auto; }
  .st_mra_i { margin-right: auto !important; }
  .st_mba { margin-bottom: auto; }
  .st_mba_i { margin-bottom: auto !important; }
  .st_m0 { margin: 0; }
  .st_m0_i { margin: 0 !important; }
  .st_mt0 { margin-top: 0; }
  .st_mt0_i { margin-top: 0 !important; }
  .st_ml0 { margin-left: 0; }
  .st_ml0_i { margin-left: 0 !important; }
  .st_mr0 { margin-right: 0; }
  .st_mr0_i { margin-right: 0 !important; }
  .st_mb0 { margin-bottom: 0; }
  .st_mb0_i { margin-bottom: 0 !important; }
  .st_m1 { margin: 1px; }
  .st_mt1 { margin-top: 1px; }
  .st_ml1 { margin-left: 1px; }
  .st_mr1 { margin-right: 1px; }
  .st_mb1 { margin-bottom: 1px; }
  .st_m2 { margin: 2px; }
  .st_mt2 { margin-top: 2px; }
  .st_ml2 { margin-left: 2px; }
  .st_mr2 { margin-right: 2px; }
  .st_mb2 { margin-bottom: 2px; }
  .st_m3 { margin: 3px; }
  .st_mt3 { margin-top: 3px; }
  .st_ml3 { margin-left: 3px; }
  .st_mr3 { margin-right: 3px; }
  .st_mb3 { margin-bottom: 3px; }
  .st_m4 { margin: 4px; }
  .st_mt4 { margin-top: 4px; }
  .st_ml4 { margin-left: 4px; }
  .st_mr4 { margin-right: 4px; }
  .st_mb4 { margin-bottom: 4px; }
  .st_m5 { margin: 5px; }
  .st_mt5 { margin-top: 5px; }
  .st_ml5 { margin-left: 5px; }
  .st_mr5 { margin-right: 5px; }
  .st_mb5 { margin-bottom: 5px; }
  .st_m7 { margin: 7px; }
  .st_mt7 { margin-top: 7px; }
  .st_ml7 { margin-left: 7px; }
  .st_mr7 { margin-right: 7px; }
  .st_mb7 { margin-bottom: 7px; }
  .st_m10 { margin: 10px; }
  .st_mt10 { margin-top: 10px; }
  .st_ml10 { margin-left: 10px; }
  .st_mr10 { margin-right: 10px; }
  .st_mb10 { margin-bottom: 10px; }
  .st_m13 { margin: 13px; }
  .st_mt13 { margin-top: 13px; }
  .st_ml13 { margin-left: 13px; }
  .st_mr13 { margin-right: 13px; }
  .st_mb13 { margin-bottom: 13px; }
  .st_m15 { margin: 15px; }
  .st_mt15 { margin-top: 15px; }
  .st_ml15 { margin-left: 15px; }
  .st_mr15 { margin-right: 15px; }
  .st_mb15 { margin-bottom: 15px; }
  .st_m20 { margin: 20px; }
  .st_mt20 { margin-top: 20px; }
  .st_ml20 { margin-left: 20px; }
  .st_mr20 { margin-right: 20px; }
  .st_mb20 { margin-bottom: 20px; }
  .st_m-1 { margin: -1px; }
  .st_mt-1 { margin-top: -1px; }
  .st_ml-1 { margin-left: -1px; }
  .st_mr-1 { margin-right: -1px; }
  .st_mb-1 { margin-bottom: -1px; }
  .st_m-2 { margin: -2px; }
  .st_mt-2 { margin-top: -2px; }
  .st_ml-2 { margin-left: -2px; }
  .st_mr-2 { margin-right: -2px; }
  .st_mb-2 { margin-bottom: -2px; }
  .st_m-3 { margin: -3px; }
  .st_mt-3 { margin-top: -3px; }
  .st_ml-3 { margin-left: -3px; }
  .st_mr-3 { margin-right: -3px; }
  .st_mb-3 { margin-bottom: -3px; }
  .st_m-4 { margin: -4px; }
  .st_mt-4 { margin-top: -4px; }
  .st_ml-4 { margin-left: -4px; }
  .st_mr-4 { margin-right: -4px; }
  .st_mb-4 { margin-bottom: -4px; }
  .st_m-5 { margin: -5px; }
  .st_mt-5 { margin-top: -5px; }
  .st_ml-5 { margin-left: -5px; }
  .st_mr-5 { margin-right: -5px; }
  .st_mb-5 { margin-bottom: -5px; }
  .st_m-7 { margin: -7px; }
  .st_mt-7 { margin-top: -7px; }
  .st_ml-7 { margin-left: -7px; }
  .st_mr-7 { margin-right: -7px; }
  .st_mb-7 { margin-bottom: -7px; }
  .st_m-10 { margin: -10px; }
  .st_mt-10 { margin-top: -10px; }
  .st_ml-10 { margin-left: -10px; }
  .st_mr-10 { margin-right: -10px; }
  .st_mb-10 { margin-bottom: -10px; }
  .st_m-13 { margin: -13px; }
  .st_mt-13 { margin-top: -13px; }
  .st_ml-13 { margin-left: -13px; }
  .st_mr-13 { margin-right: -13px; }
  .st_mb-13 { margin-bottom: -13px; }
  .st_m-15 { margin: -15px; }
  .st_mt-15 { margin-top: -15px; }
  .st_ml-15 { margin-left: -15px; }
  .st_mr-15 { margin-right: -15px; }
  .st_mb-15 { margin-bottom: -15px; }
  .st_m-20 { margin: -20px; }
  .st_mt-20 { margin-top: -20px; }
  .st_ml-20 { margin-left: -20px; }
  .st_mr-20 { margin-right: -20px; }
  .st_mb-20 { margin-bottom: -20px; }
  .st_m-25 { margin: -25px; }
  .st_mt-25 { margin-top: -25px; }
  .st_ml-25 { margin-left: -25px; }
  .st_mr-25 { margin-right: -25px; }
  .st_mb-25 { margin-bottom: -25px; }
  .st_m-30 { margin: -30px; }
  .st_mt-30 { margin-top: -30px; }
  .st_ml-30 { margin-left: -30px; }
  .st_mr-30 { margin-right: -30px; }
  .st_mb-30 { margin-bottom: -30px; }

  .st_p0 { padding: 0 !important; }
  .st_pt0 { padding-top: 0 !important; }
  .st_pl0 { padding-left: 0 !important; }
  .st_pr0 { padding-right: 0 !important; }
  .st_pb0 { padding-bottom: 0 !important; }
  .st_p1 { padding: 1px !important; }
  .st_pt1 { padding-top: 1px !important; }
  .st_pl1 { padding-left: 1px !important; }
  .st_pr1 { padding-right: 1px !important; }
  .st_pb1 { padding-bottom: 1px !important; }
  .st_p2 { padding: 2px !important; }
  .st_pt2 { padding-top: 2px !important; }
  .st_pl2 { padding-left: 2px !important; }
  .st_pr2 { padding-right: 2px !important; }
  .st_pb2 { padding-bottom: 2px !important; }
  .st_p3 { padding: 3px !important; }
  .st_pt3 { padding-top: 3px !important; }
  .st_pl3 { padding-left: 3px !important; }
  .st_pr3 { padding-right: 3px !important; }
  .st_pb3 { padding-bottom: 3px !important; }
  .st_p4 { padding: 4px !important; }
  .st_pt4 { padding-top: 4px !important; }
  .st_pl4 { padding-left: 4px !important; }
  .st_pr4 { padding-right: 4px !important; }
  .st_pb4 { padding-bottom: 4px !important; }
  .st_p5 { padding: 5px !important; }
  .st_pt5 { padding-top: 5px !important; }
  .st_pl5 { padding-left: 5px !important; }
  .st_pr5 { padding-right: 5px !important; }
  .st_pb5 { padding-bottom: 5px !important; }
  .st_p7 { padding: 7px !important; }
  .st_pt7 { padding-top: 7px !important; }
  .st_pl7 { padding-left: 7px !important; }
  .st_pr7 { padding-right: 7px !important; }
  .st_pb7 { padding-bottom: 7px !important; }
  .st_p10 { padding: 10px !important; }
  .st_pt10 { padding-top: 10px !important; }
  .st_pl10 { padding-left: 10px !important; }
  .st_pr10 { padding-right: 10px !important; }
  .st_pb10 { padding-bottom: 10px !important; }
  .st_p13 { padding: 13px !important; }
  .st_pt13 { padding-top: 13px !important; }
  .st_pl13 { padding-left: 13px !important; }
  .st_pr13 { padding-right: 13px !important; }
  .st_pb13 { padding-bottom: 13px !important; }
  .st_p15 { padding: 15px !important; }
  .st_pt15 { padding-top: 15px !important; }
  .st_pl15 { padding-left: 15px !important; }
  .st_pr15 { padding-right: 15px !important; }
  .st_pb15 { padding-bottom: 15px !important; }
  .st_p20 { padding: 20px !important; }
  .st_pt20 { padding-top: 20px !important; }
  .st_pl20 { padding-left: 20px !important; }
  .st_pr20 { padding-right: 20px !important; }
  .st_pb20 { padding-bottom: 20px !important; }
  .st_p25 { padding: 25px !important; }
  .st_pt25 { padding-top: 25px !important; }
  .st_pl25 { padding-left: 25px !important; }
  .st_pr25 { padding-right: 25px !important; }
  .st_pb25 { padding-bottom: 25px !important; }
  .st_p30 { padding: 30px !important; }
  .st_pt30 { padding-top: 30px !important; }
  .st_pl30 { padding-left: 30px !important; }
  .st_pr30 { padding-right: 30px !important; }
  .st_pb30 { padding-bottom: 30px !important; }

  .st_fw800, .st_fw800 *, .st_black, .st_black * { font-weight: 800; }
  .st_fw700, .st_fw700 *, .st_bold, .st_bold * { font-weight: 700; }
  .st_fw600, .st_fw600 *, .st_semibold, .st_semibold * { font-weight: 600; }
  .st_fw400, .st_fw400 * { font-weight: 400; }
  .st_fw300, .st_fw300 *, .st_semilight, .st_semilight * { font-weight: 300; }

  .st_tal { text-align: left; }
  .st_tac { text-align: center; }
  .st_tar { text-align: right; }

  .st_vat { vertical-align: top; }
  .st_vam { vertical-align: middle; }
  .st_vab { vertical-align: bottom; }
  .st_vabl { vertical-align: baseline; }

  .st_fz0 { font-size: 0; }
  .st_fz0_i { font-size: 0 !important; }
  .st_fz10, .st_fz10_a, .st_fz10_a * { font-size: 10px; }
  .st_fz10_i, .st_fz10_a_i, .st_fz10_a_i * { font-size: 10px !important; }
  .st_fz11, .st_fz11_a, .st_fz11_a * { font-size: 11px; }
  .st_fz11_i, .st_fz11_a_i, .st_fz11_a_i * { font-size: 11px !important; }
  .st_fz12, .st_fz12_a, .st_fz12_a * { font-size: 12px; }
  .st_fz12_i, .st_fz12_a_i, .st_fz12_a_i * { font-size: 12px !important; }
  .st_fz14, .st_fz14_a, .st_fz14_a * { font-size: 14px; }
  .st_fz14_i, .st_fz14_a_i, .st_fz14_a_i * { font-size: 14px !important; }
  .st_fz15 { font-size: 15px; }
  .st_fz15_i { font-size: 15px !important; }
  .st_fz16 { font-size: 16px; }
  .st_fz16_i { font-size: 16px !important; }
  .st_fz18 { font-size: 18px; }
  .st_fz18_i { font-size: 18px !important; }
  .st_fz20 { font-size: 20px; }
  .st_fz20_i { font-size: 20px !important; }
  .st_fz22 { font-size: 22px; }
  .st_fz22_i { font-size: 22px !important; }
  .st_fz24 { font-size: 24px; }
  .st_fz24_i { font-size: 24px !important; }
  .st_fz26 { font-size: 26px; }
  .st_fz26_i { font-size: 26px !important; }
  .st_fz28 { font-size: 28px; }
  .st_fz28_i { font-size: 28px !important; }
  .st_fz30 { font-size: 30px; }
  .st_fz30_i { font-size: 30px !important; }
  .st_fsi { font-style: italic; }

  .st_fln { float: none; }
  .st_fll { float: left; }
  .st_flr { float: right; }


  .form-popup-container .form-block-container {
    width: 100% !important;
  }


  .menu-is-opened {
    position: relative;
    overflow: hidden !important;
  }


  .header {
    z-index: 100010;
  }
    .header-content {
      background: #fff;
      padding: 5px 0px;
      z-index: 98;
    }
      .header-fixed .header-content {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
      }

      .header-phones-container .callback-button {
        content: "";
        font-size: 0px;
        border-radius: 2px;
        padding: 12px;
      }
        .header-content .callback-button:before {
          content: "";
          display: block;
          background: url('/i/phone-icon--white.svg') no-repeat left center transparent;
          background-size: contain;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }


      .header-social-container {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        display: none;
        margin-top: -3px;
      }
        .header-social-container.initialized {
          display: flex;
        }


      .header-menu-button-container {
        display: block;
        padding-left: 10px;
      }
        .header-menu-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #fff;
          border: 2px solid #ccc;
          border-radius: 2px;
          width: 44px;
          height: 44px;
          padding: 8px;
          cursor: pointer;
        }
          .header-menu-button span {
            display: block;
            position: relative;
          }
            .header-menu-button span,
            .header-menu-button span:before,
            .header-menu-button span:after {
              content: "";
              width: 100%;
              height: 3px;
              background: #999;

              -webkit-transition: background-color 0.2s ease 0s;
              -moz-transition: background-color 0.2s ease 0s;
              -o-transition: background-color 0.2s ease 0s;
              transition: background-color 0.2s ease 0s;
            }
            .header-menu-button span:before {
              top: -8px;
              position: absolute;
            }
            .header-menu-button span:after {
              bottom: -8px;
              position: absolute;
            }

            .header-menu-button:hover span,
            .header-menu-button:hover span:before,
            .header-menu-button:hover span:after,
            .header-menu-checkbox:checked + .header-menu-button span,
            .header-menu-checkbox:checked + .header-menu-button span:before,
            .header-menu-checkbox:checked + .header-menu-button span:after {
              background: #298580;
            }

    .header-menu-container {
      background: transparent;
      box-shadow: none;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 99;
      visibility: hidden;

      -webkit-transition: visibility 0s 0.3s;
      -moz-transition: visibility 0s 0.3s;
      -o-transition: visibility 0s 0.3s;
      transition: visibility 0s 0.3s;
    }
      .menu-is-opened .header-menu-container {
        visibility: visible;

        -webkit-transition: visibility 0s 0s;
        -moz-transition: visibility 0s 0s;
        -o-transition: visibility 0s 0s;
        transition: visibility 0s 0s;
      }

      .header-menu-container:before {
        content: "";
        background: #000;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        opacity: 0;

        -webkit-transition: opacity 0.3s ease 0s;
        -moz-transition: opacity 0.3s ease 0s;
        -o-transition: opacity 0.3s ease 0s;
        transition: opacity 0.3s ease 0s;
      }
        .menu-is-opened .header-menu-container:before {
          opacity: 0.6;
        }

      .header-menu-container > .wrapper {
        background: #fff;
        position: absolute;
        top: 0px;
        right: -100%;
        bottom: 0px;
        width: auto;
        padding: 62px 20px 20px 5px;
        overflow: hidden;

        -webkit-transition: right 0.3s ease 0s;
        -moz-transition: right 0.3s ease 0s;
        -o-transition: right 0.3s ease 0s;
        transition: right 0.3s ease 0s;
      }
        .menu-is-opened .header-menu-container > .wrapper {
          right: 0px;
        }

        .header-menu-close-button {
          display: block;
          font-size: 0px;
          background: url('/i/cross-icon--gray.svg') no-repeat top right transparent;
          background-size: contain;
          position: absolute;
          border: 5px solid transparent;
          top: 15px;
          right: 15px;
          width: 32px;
          height: 32px;
          cursor: pointer;
          z-index: 2;
        }

        .header-menu-container ul {
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          height: 100%;
          padding-left: 15px;
          overflow: scroll;
        }
          .header-menu-container li {
            flex-grow: 0;
          }
            .header-menu-container li > div {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: stretch;
              font-size: 0px;
              padding-bottom: 15px;
            }
              .header-menu-container .dropmarker {
                content: "";
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: transparent;
                border-bottom: 1px solid #cbcbcb;
                font-size: 0px;
                min-width: 35px;
                cursor: pointer;

                -webkit-transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
                -moz-transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
                -o-transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
                transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
              }
                .header-menu-container .dropmarker:hover,
                .header-menu-container li.is-hovered > div > .dropmarker {
                  border-bottom-color: #298580;
                }

                .header-menu-container .dropmarker:before {
                  content: "";
                  border-top: 8px solid #89826c;
                  border-left: 8px solid transparent;
                  border-right: 8px solid transparent;
                  display: block;
                  width: 0px;
                  height: 0px;
                  margin: auto;
                }
                  .header-menu-container li.is-hovered > div > .dropmarker:before {
                    border-top: none;
                    border-bottom: 8px solid #89826c;
                  }


              .header-menu-container li > div > div:first-child {
                width: 100%;
              }
                .header-menu-container li > div > div > * {
                  color: #333 !important;
                  font-size: 1.1666rem;
                  text-align: left;
                  padding: 0.4em 3em 0.1em 0.2em !important;
                  border-bottom: 1px solid #cbcbcb;
                  width: 100%;

                  -webkit-transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
                  -moz-transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
                  -o-transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
                  transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
                }
                  .header-menu-container li > div > div:hover > *,
                  .header-menu-container li.active > div > div > * {
                    color: #298580 !important;
                    background: transparent;
                    border-bottom-color: #298580;
                  }
                  .header-menu-container li.active > div > div > * {
                    font-weight: 600;
                  }


            .header-menu-container li > ul {
              background: transparent;
              border: none;
              box-shadow: none;
              top: 0px;
              height: 0px;
              position: relative;
              visibility: visible;
            }
              .header-menu-container li.is-hovered > ul {
                height: auto;
                padding-bottom: 15px;
              }


  .main-slide-code-container {
    position: relative;
  }
    .main-slide-code-container > div {
      background: #f0f0f0;
    }
      .main-slide-code-container > div > div {
        background: none;
        width: 100%;
        padding: 10px 0px;
        margin: 0px;
      }


  .info-page-image { width: 33.3333%; }


  .list-view-skin-mode {
    display: none;
  }

  .filter-dropdown-button .filter-caption {
    font-size: 1.1rem;
  }

  .filter-dropdown-wrapper {
    margin-left: -20px;
  }
    .filter-dropdown-wrapper > div {
      width: 50%;
      padding-left: 20px;
    }
      .filter-dropdown-container {
        position: relative;
        top: 0px;
        left: 0px;
        width: 100%;
      }
        .filter-slider-container {
          display: none;
        }
        .filter-slider-inputs {
          margin-top: 0px;
        }


  .list-view {
    margin: -20px 0px 0px -20px;
  }
    .list-view-item {
      width: 50%;
      padding: 20px 0px 0px 20px;
    }
      .list-view-item-image a,
      .list-view-item-image span {
        padding-top: 70%;
      }

      .list-view-item-text {
        padding: 15px 18px 18px;
      }
        .list-view-item-name a,
        .list-view-item-name span {
          font-size: 1.4rem;
        }

        .list-view-item-notice,
        .list-view-item-parameters {
          padding: 12px 0px 0px;
        }


  .advantages-block-container .list-view {
    margin: -10px 0px 0px 0px;
  }
    .advantages-block-container .list-view-item {
      padding: 10px 0px 0px 0px;
    }
      .advantages-block-container .list-view-item-image {
        margin: 0px 10px;
      }


  .banks-block-container .list-view-item {
    width: 33.3333%;
  }


  .product-chess-floor-label {
    min-width: 70px;
  }


  .callback-manager-block .list-view-item-image {
    max-width: 200px;
  }

  .contact-page-info {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
    .contact-page-info > div {
      width: auto;
    }


  .footer-social-container a {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 699px) {
  .m_dn { display: none; }
  .m_dn_i { display: none !important; }
  .m_db { display: block; }
  .m_dib { display: inline-block; }
  .m_dt { display: table; }
  .m_dtr { display: table-row; }
  .m_dtc { display: table-cell; }
  /* Flex */
  .m_dfdr { display: flex; flex-direction: row; }
  .m_dfdrr { display: flex; flex-direction: row-reverse; }
  .m_dfdc { display: flex; flex-direction: column; }
  .m_dfdcr { display: flex; flex-direction: column-reverse; }
  .m_fwn { flex-wrap: nowrap; }
  .m_fww { flex-wrap: wrap; }
  .m_fwwr { flex-wrap: wrap-reverse; }
  .m_fjcs { justify-content: flex-start; }
  .m_fjce { justify-content: flex-end; }
  .m_fjcc { justify-content: center; }
  .m_fjcsa { justify-content: space-around; }
  .m_fjcsb { justify-content: space-between; }
  .m_fais { align-items: flex-start; }
  .m_faie { align-items: flex-end; }
  .m_faic { align-items: center; }
  .m_faib { align-items: baseline; }
  .m_faist { align-items: stretch; }

  .m_h0 { height: 0px; }
  .m_hfull { height: 100%; }
  .m_hauto { height: auto; }

  .m_w0 { width: 0px; }
  .m_w1 { width: 1px; }
  .m_w10p { width: 10%; }
  .m_w12p { width: 12.5%; }
  .m_w15p { width: 15%; }
  .m_w20p { width: 20%; }
  .m_w25p { width: 25%; }
  .m_w33p { width: 33.3333%; }
  .m_w35p { width: 35%; }
  .m_w40p { width: 40%; }
  .m_w50p { width: 50%; }
  .m_w60p { width: 60%; }
  .m_w65p { width: 65%; }
  .m_w66p { width: 66.6666%; }
  .m_w70p { width: 70%; }
  .m_w75p { width: 75%; }
  .m_wfull { width: 100%; }
  .m_wauto { width: auto; }

  .m_m0a { margin: 0 auto; }
  .m_m0a_i { margin: 0 auto !important; }
  .m_ma0 { margin: auto 0; }
  .m_ma0_i { margin: auto 0 !important; }
  .m_ma { margin: auto; }
  .m_ma_i { margin: auto !important; }
  .m_mta { margin-top: auto; }
  .m_mta_i { margin-top: auto !important; }
  .m_mla { margin-left: auto; }
  .m_mla_i { margin-left: auto !important; }
  .m_mra { margin-right: auto; }
  .m_mra_i { margin-right: auto !important; }
  .m_mba { margin-bottom: auto; }
  .m_mba_i { margin-bottom: auto !important; }
  .m_m0 { margin: 0; }
  .m_m0_i { margin: 0 !important; }
  .m_mt0 { margin-top: 0; }
  .m_mt0_i { margin-top: 0 !important; }
  .m_ml0 { margin-left: 0; }
  .m_ml0_i { margin-left: 0 !important; }
  .m_mr0 { margin-right: 0; }
  .m_mr0_i { margin-right: 0 !important; }
  .m_mb0 { margin-bottom: 0; }
  .m_mb0_i { margin-bottom: 0 !important; }
  .m_m1 { margin: 1px; }
  .m_mt1 { margin-top: 1px; }
  .m_ml1 { margin-left: 1px; }
  .m_mr1 { margin-right: 1px; }
  .m_mb1 { margin-bottom: 1px; }
  .m_m2 { margin: 2px; }
  .m_mt2 { margin-top: 2px; }
  .m_ml2 { margin-left: 2px; }
  .m_mr2 { margin-right: 2px; }
  .m_mb2 { margin-bottom: 2px; }
  .m_m3 { margin: 3px; }
  .m_mt3 { margin-top: 3px; }
  .m_ml3 { margin-left: 3px; }
  .m_mr3 { margin-right: 3px; }
  .m_mb3 { margin-bottom: 3px; }
  .m_m4 { margin: 4px; }
  .m_mt4 { margin-top: 4px; }
  .m_ml4 { margin-left: 4px; }
  .m_mr4 { margin-right: 4px; }
  .m_mb4 { margin-bottom: 4px; }
  .m_m5 { margin: 5px; }
  .m_mt5 { margin-top: 5px; }
  .m_ml5 { margin-left: 5px; }
  .m_mr5 { margin-right: 5px; }
  .m_mb5 { margin-bottom: 5px; }
  .m_m7 { margin: 7px; }
  .m_mt7 { margin-top: 7px; }
  .m_ml7 { margin-left: 7px; }
  .m_mr7 { margin-right: 7px; }
  .m_mb7 { margin-bottom: 7px; }
  .m_m10 { margin: 10px; }
  .m_mt10 { margin-top: 10px; }
  .m_ml10 { margin-left: 10px; }
  .m_mr10 { margin-right: 10px; }
  .m_mb10 { margin-bottom: 10px; }
  .m_m13 { margin: 13px; }
  .m_mt13 { margin-top: 13px; }
  .m_ml13 { margin-left: 13px; }
  .m_mr13 { margin-right: 13px; }
  .m_mb13 { margin-bottom: 13px; }
  .m_m15 { margin: 15px; }
  .m_mt15 { margin-top: 15px; }
  .m_ml15 { margin-left: 15px; }
  .m_mr15 { margin-right: 15px; }
  .m_mb15 { margin-bottom: 15px; }
  .m_m20 { margin: 20px; }
  .m_mt20 { margin-top: 20px; }
  .m_ml20 { margin-left: 20px; }
  .m_mr20 { margin-right: 20px; }
  .m_mb20 { margin-bottom: 20px; }
  .m_m-1 { margin: -1px; }
  .m_mt-1 { margin-top: -1px; }
  .m_ml-1 { margin-left: -1px; }
  .m_mr-1 { margin-right: -1px; }
  .m_mb-1 { margin-bottom: -1px; }
  .m_m-2 { margin: -2px; }
  .m_mt-2 { margin-top: -2px; }
  .m_ml-2 { margin-left: -2px; }
  .m_mr-2 { margin-right: -2px; }
  .m_mb-2 { margin-bottom: -2px; }
  .m_m-3 { margin: -3px; }
  .m_mt-3 { margin-top: -3px; }
  .m_ml-3 { margin-left: -3px; }
  .m_mr-3 { margin-right: -3px; }
  .m_mb-3 { margin-bottom: -3px; }
  .m_m-4 { margin: -4px; }
  .m_mt-4 { margin-top: -4px; }
  .m_ml-4 { margin-left: -4px; }
  .m_mr-4 { margin-right: -4px; }
  .m_mb-4 { margin-bottom: -4px; }
  .m_m-5 { margin: -5px; }
  .m_mt-5 { margin-top: -5px; }
  .m_ml-5 { margin-left: -5px; }
  .m_mr-5 { margin-right: -5px; }
  .m_mb-5 { margin-bottom: -5px; }
  .m_m-7 { margin: -7px; }
  .m_mt-7 { margin-top: -7px; }
  .m_ml-7 { margin-left: -7px; }
  .m_mr-7 { margin-right: -7px; }
  .m_mb-7 { margin-bottom: -7px; }
  .m_m-10 { margin: -10px; }
  .m_mt-10 { margin-top: -10px; }
  .m_ml-10 { margin-left: -10px; }
  .m_mr-10 { margin-right: -10px; }
  .m_mb-10 { margin-bottom: -10px; }
  .m_m-13 { margin: -13px; }
  .m_mt-13 { margin-top: -13px; }
  .m_ml-13 { margin-left: -13px; }
  .m_mr-13 { margin-right: -13px; }
  .m_mb-13 { margin-bottom: -13px; }
  .m_m-15 { margin: -15px; }
  .m_mt-15 { margin-top: -15px; }
  .m_ml-15 { margin-left: -15px; }
  .m_mr-15 { margin-right: -15px; }
  .m_mb-15 { margin-bottom: -15px; }
  .m_m-20 { margin: -20px; }
  .m_mt-20 { margin-top: -20px; }
  .m_ml-20 { margin-left: -20px; }
  .m_mr-20 { margin-right: -20px; }
  .m_mb-20 { margin-bottom: -20px; }
  .m_m-25 { margin: -25px; }
  .m_mt-25 { margin-top: -25px; }
  .m_ml-25 { margin-left: -25px; }
  .m_mr-25 { margin-right: -25px; }
  .m_mb-25 { margin-bottom: -25px; }
  .m_m-30 { margin: -30px; }
  .m_mt-30 { margin-top: -30px; }
  .m_ml-30 { margin-left: -30px; }
  .m_mr-30 { margin-right: -30px; }
  .m_mb-30 { margin-bottom: -30px; }

  .m_p0 { padding: 0 !important; }
  .m_pt0 { padding-top: 0 !important; }
  .m_pl0 { padding-left: 0 !important; }
  .m_pr0 { padding-right: 0 !important; }
  .m_pb0 { padding-bottom: 0 !important; }
  .m_p1 { padding: 1px !important; }
  .m_pt1 { padding-top: 1px !important; }
  .m_pl1 { padding-left: 1px !important; }
  .m_pr1 { padding-right: 1px !important; }
  .m_pb1 { padding-bottom: 1px !important; }
  .m_p2 { padding: 2px !important; }
  .m_pt2 { padding-top: 2px !important; }
  .m_pl2 { padding-left: 2px !important; }
  .m_pr2 { padding-right: 2px !important; }
  .m_pb2 { padding-bottom: 2px !important; }
  .m_p3 { padding: 3px !important; }
  .m_pt3 { padding-top: 3px !important; }
  .m_pl3 { padding-left: 3px !important; }
  .m_pr3 { padding-right: 3px !important; }
  .m_pb3 { padding-bottom: 3px !important; }
  .m_p4 { padding: 4px !important; }
  .m_pt4 { padding-top: 4px !important; }
  .m_pl4 { padding-left: 4px !important; }
  .m_pr4 { padding-right: 4px !important; }
  .m_pb4 { padding-bottom: 4px !important; }
  .m_p5 { padding: 5px !important; }
  .m_pt5 { padding-top: 5px !important; }
  .m_pl5 { padding-left: 5px !important; }
  .m_pr5 { padding-right: 5px !important; }
  .m_pb5 { padding-bottom: 5px !important; }
  .m_p7 { padding: 7px !important; }
  .m_pt7 { padding-top: 7px !important; }
  .m_pl7 { padding-left: 7px !important; }
  .m_pr7 { padding-right: 7px !important; }
  .m_pb7 { padding-bottom: 7px !important; }
  .m_p10 { padding: 10px !important; }
  .m_pt10 { padding-top: 10px !important; }
  .m_pl10 { padding-left: 10px !important; }
  .m_pr10 { padding-right: 10px !important; }
  .m_pb10 { padding-bottom: 10px !important; }
  .m_p13 { padding: 13px !important; }
  .m_pt13 { padding-top: 13px !important; }
  .m_pl13 { padding-left: 13px !important; }
  .m_pr13 { padding-right: 13px !important; }
  .m_pb13 { padding-bottom: 13px !important; }
  .m_p15 { padding: 15px !important; }
  .m_pt15 { padding-top: 15px !important; }
  .m_pl15 { padding-left: 15px !important; }
  .m_pr15 { padding-right: 15px !important; }
  .m_pb15 { padding-bottom: 15px !important; }
  .m_p20 { padding: 20px !important; }
  .m_pt20 { padding-top: 20px !important; }
  .m_pl20 { padding-left: 20px !important; }
  .m_pr20 { padding-right: 20px !important; }
  .m_pb20 { padding-bottom: 20px !important; }
  .m_p25 { padding: 25px !important; }
  .m_pt25 { padding-top: 25px !important; }
  .m_pl25 { padding-left: 25px !important; }
  .m_pr25 { padding-right: 25px !important; }
  .m_pb25 { padding-bottom: 25px !important; }
  .m_p30 { padding: 30px !important; }
  .m_pt30 { padding-top: 30px !important; }
  .m_pl30 { padding-left: 30px !important; }
  .m_pr30 { padding-right: 30px !important; }
  .m_pb30 { padding-bottom: 30px !important; }

  .m_fw800, .m_fw800 *, .m_black, .m_black * { font-weight: 800; }
  .m_fw700, .m_fw700 *, .m_bold, .m_bold * { font-weight: 700; }
  .m_fw600, .m_fw600 *, .m_semibold, .m_semibold * { font-weight: 600; }
  .m_fw400, .m_fw400 * { font-weight: 400; }
  .m_fw300, .m_fw300 *, .m_semilight, .m_semilight * { font-weight: 300; }

  .m_tal { text-align: left; }
  .m_tac { text-align: center; }
  .m_tar { text-align: right; }

  .m_vat { vertical-align: top; }
  .m_vam { vertical-align: middle; }
  .m_vab { vertical-align: bottom; }
  .m_vabl { vertical-align: baseline; }

  .m_fz0 { font-size: 0; }
  .m_fz0_i { font-size: 0 !important; }
  .m_fz10, .m_fz10_a, .m_fz10_a * { font-size: 10px; }
  .m_fz10_i, .m_fz10_a_i, .m_fz10_a_i * { font-size: 10px !important; }
  .m_fz11, .m_fz11_a, .m_fz11_a * { font-size: 11px; }
  .m_fz11_i, .m_fz11_a_i, .m_fz11_a_i * { font-size: 11px !important; }
  .m_fz12, .m_fz12_a, .m_fz12_a * { font-size: 12px; }
  .m_fz12_i, .m_fz12_a_i, .m_fz12_a_i * { font-size: 12px !important; }
  .m_fz14, .m_fz14_a, .m_fz14_a * { font-size: 14px; }
  .m_fz14_i, .m_fz14_a_i, .m_fz14_a_i * { font-size: 14px !important; }
  .m_fz15 { font-size: 15px; }
  .m_fz15_i { font-size: 15px !important; }
  .m_fz16 { font-size: 16px; }
  .m_fz16_i { font-size: 16px !important; }
  .m_fz18 { font-size: 18px; }
  .m_fz18_i { font-size: 18px !important; }
  .m_fz20 { font-size: 20px; }
  .m_fz20_i { font-size: 20px !important; }
  .m_fz22 { font-size: 22px; }
  .m_fz22_i { font-size: 22px !important; }
  .m_fz24 { font-size: 24px; }
  .m_fz24_i { font-size: 24px !important; }
  .m_fz26 { font-size: 26px; }
  .m_fz26_i { font-size: 26px !important; }
  .m_fz28 { font-size: 28px; }
  .m_fz28_i { font-size: 28px !important; }
  .m_fz30 { font-size: 30px; }
  .m_fz30_i { font-size: 30px !important; }
  .m_fsi { font-style: italic; }

  .m_fln { float: none; }
  .m_fll { float: left; }
  .m_flr { float: right; }


  .hr {
    padding: 5% 0%;
  }


  .header-logo-container {
    min-width: 120px;
  }
  .header-slogan-container {
    display: none;
  }
  .header-phones-container {
    width: 100%;
  }
    .header-phones,
    .header-phones div {
      font-size: 0px;
    }
      .header-phones a {
        font-size: 1.25rem;
      }

  .header-menu-container > .wrapper {
    width: 100%;
  }


  .advantages-block-container .list-view-item-name {
    font-size: 16px;
  }


  .filter-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
    .filter-elements-container {
      width: 100%;
    }
      .filter-dropdown-wrapper {
        margin-right: 0px;
        margin-bottom: 20px;
      }

    .filter-buttons-container > div {
      flex-direction: row;
      justify-content: center;
      min-width: 0px;
    }
      .filter-buttons-container > div > div + div {
        padding: 0px 0px 0px 15px;
      }


  .product-chess-section {
    width: 50%;
  }


  .main-manager-block .form-block-container {
    display: none;
  }


  .product-page-left + div {
    padding-left: 3%;
  }
    .product-page-info-wrapper .product-page-params table tr > th {
      width: 60%;
    }


  .document-list > div > div + div > div {
    width: 100%;
  }


  .callback-manager-block .list-view-item-image {
    flex-shrink: 3;
  }
  .callback-manager-block .list-view-item-text {
    flex-shrink: 1;
    padding: 0px 0px 10px 15px;
  }


  .footer-callback-msg-block {
    padding: 1.6em 0em;
  }
    .footer-callback-msg-block > .wrapper {
      flex-direction: column;
      justify-content: flex-start;
    }
      .footer-callback-msg-block > .wrapper > div + div {
        padding-top: 1.2em;
      }

      .footer-callback-msg-block .subheader {
        font-size: 2.0833rem;
        text-align: center;
      }
      .footer-callback-msg-notice {
        font-size: 1.1rem;
        text-align: center;
      }


  .footer-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 6% 0%;
    margin: 0px;
  }
    .footer-content > div {
      flex-grow: 0;
      padding: 0px;
    }
    .footer-content > div:first-child {
      display: none;
    }

    .footer-info-container {
      padding-top: 15px !important;
    }
      .footer-social-container {
        margin: -10px 0px 0px -10px;
      }
        .footer-social-container > div {
          padding: 10px 0px 0px 10px;
        }

    .footer-menu-container ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      margin: 0px 0px 0px -15px;
    }
      .footer-menu-container li {
        padding: 0px 0px 0px 15px;
        margin: 0px !important;
      }
        .footer-menu-container li a {
          text-decoration: underline;
        }
          .footer-menu-container li a:hover {
            text-decoration: none;
          }


  .footer-copyrights {
    padding: 3% 0%;
  }
}

@media screen and (max-width: 599px) {
}

@media screen and (max-width: 559px) {
  .advantages-block-container .list-view-item {
    width: 50%;
  }
}

@media screen and (max-width: 479px) {
  .sm_dn { display: none; }
  .sm_dn_i { display: none !important; }
  .sm_db { display: block; }
  .sm_dib { display: inline-block; }
  .sm_dt { display: table; }
  .sm_dtr { display: table-row; }
  .sm_dtc { display: table-cell; }
  /* Flex */
  .sm_dfdr { display: flex; flex-direction: row; }
  .sm_dfdrr { display: flex; flex-direction: row-reverse; }
  .sm_dfdc { display: flex; flex-direction: column; }
  .sm_dfdcr { display: flex; flex-direction: column-reverse; }
  .sm_fwn { flex-wrap: nowrap; }
  .sm_fww { flex-wrap: wrap; }
  .sm_fwwr { flex-wrap: wrap-reverse; }
  .sm_fjcs { justify-content: flex-start; }
  .sm_fjce { justify-content: flex-end; }
  .sm_fjcc { justify-content: center; }
  .sm_fjcsa { justify-content: space-around; }
  .sm_fjcsb { justify-content: space-between; }
  .sm_fais { align-items: flex-start; }
  .sm_faie { align-items: flex-end; }
  .sm_faic { align-items: center; }
  .sm_faib { align-items: baseline; }
  .sm_faist { align-items: stretch; }

  .sm_h0 { height: 0px; }
  .sm_hfull { height: 100%; }
  .sm_hauto { height: auto; }

  .sm_w0 { width: 0px; }
  .sm_w1 { width: 1px; }
  .sm_w10p { width: 10%; }
  .sm_w12p { width: 12.5%; }
  .sm_w15p { width: 15%; }
  .sm_w20p { width: 20%; }
  .sm_w25p { width: 25%; }
  .sm_w33p { width: 33.3333%; }
  .sm_w35p { width: 35%; }
  .sm_w40p { width: 40%; }
  .sm_w50p { width: 50%; }
  .sm_w60p { width: 60%; }
  .sm_w65p { width: 65%; }
  .sm_w66p { width: 66.6666%; }
  .sm_w70p { width: 70%; }
  .sm_w75p { width: 75%; }
  .sm_wfull { width: 100%; }
  .sm_wauto { width: auto; }

  .sm_m0a { margin: 0 auto; }
  .sm_m0a_i { margin: 0 auto !important; }
  .sm_ma0 { margin: auto 0; }
  .sm_ma0_i { margin: auto 0 !important; }
  .sm_ma { margin: auto; }
  .sm_ma_i { margin: auto !important; }
  .sm_mta { margin-top: auto; }
  .sm_mta_i { margin-top: auto !important; }
  .sm_mla { margin-left: auto; }
  .sm_mla_i { margin-left: auto !important; }
  .sm_mra { margin-right: auto; }
  .sm_mra_i { margin-right: auto !important; }
  .sm_mba { margin-bottom: auto; }
  .sm_mba_i { margin-bottom: auto !important; }
  .sm_m0 { margin: 0; }
  .sm_m0_i { margin: 0 !important; }
  .sm_mt0 { margin-top: 0; }
  .sm_mt0_i { margin-top: 0 !important; }
  .sm_ml0 { margin-left: 0; }
  .sm_ml0_i { margin-left: 0 !important; }
  .sm_mr0 { margin-right: 0; }
  .sm_mr0_i { margin-right: 0 !important; }
  .sm_mb0 { margin-bottom: 0; }
  .sm_mb0_i { margin-bottom: 0 !important; }
  .sm_m1 { margin: 1px; }
  .sm_mt1 { margin-top: 1px; }
  .sm_ml1 { margin-left: 1px; }
  .sm_mr1 { margin-right: 1px; }
  .sm_mb1 { margin-bottom: 1px; }
  .sm_m2 { margin: 2px; }
  .sm_mt2 { margin-top: 2px; }
  .sm_ml2 { margin-left: 2px; }
  .sm_mr2 { margin-right: 2px; }
  .sm_mb2 { margin-bottom: 2px; }
  .sm_m3 { margin: 3px; }
  .sm_mt3 { margin-top: 3px; }
  .sm_ml3 { margin-left: 3px; }
  .sm_mr3 { margin-right: 3px; }
  .sm_mb3 { margin-bottom: 3px; }
  .sm_m4 { margin: 4px; }
  .sm_mt4 { margin-top: 4px; }
  .sm_ml4 { margin-left: 4px; }
  .sm_mr4 { margin-right: 4px; }
  .sm_mb4 { margin-bottom: 4px; }
  .sm_m5 { margin: 5px; }
  .sm_mt5 { margin-top: 5px; }
  .sm_ml5 { margin-left: 5px; }
  .sm_mr5 { margin-right: 5px; }
  .sm_mb5 { margin-bottom: 5px; }
  .sm_m7 { margin: 7px; }
  .sm_mt7 { margin-top: 7px; }
  .sm_ml7 { margin-left: 7px; }
  .sm_mr7 { margin-right: 7px; }
  .sm_mb7 { margin-bottom: 7px; }
  .sm_m10 { margin: 10px; }
  .sm_mt10 { margin-top: 10px; }
  .sm_ml10 { margin-left: 10px; }
  .sm_mr10 { margin-right: 10px; }
  .sm_mb10 { margin-bottom: 10px; }
  .sm_m13 { margin: 13px; }
  .sm_mt13 { margin-top: 13px; }
  .sm_ml13 { margin-left: 13px; }
  .sm_mr13 { margin-right: 13px; }
  .sm_mb13 { margin-bottom: 13px; }
  .sm_m15 { margin: 15px; }
  .sm_mt15 { margin-top: 15px; }
  .sm_ml15 { margin-left: 15px; }
  .sm_mr15 { margin-right: 15px; }
  .sm_mb15 { margin-bottom: 15px; }
  .sm_m20 { margin: 20px; }
  .sm_mt20 { margin-top: 20px; }
  .sm_ml20 { margin-left: 20px; }
  .sm_mr20 { margin-right: 20px; }
  .sm_mb20 { margin-bottom: 20px; }
  .sm_m-1 { margin: -1px; }
  .sm_mt-1 { margin-top: -1px; }
  .sm_ml-1 { margin-left: -1px; }
  .sm_mr-1 { margin-right: -1px; }
  .sm_mb-1 { margin-bottom: -1px; }
  .sm_m-2 { margin: -2px; }
  .sm_mt-2 { margin-top: -2px; }
  .sm_ml-2 { margin-left: -2px; }
  .sm_mr-2 { margin-right: -2px; }
  .sm_mb-2 { margin-bottom: -2px; }
  .sm_m-3 { margin: -3px; }
  .sm_mt-3 { margin-top: -3px; }
  .sm_ml-3 { margin-left: -3px; }
  .sm_mr-3 { margin-right: -3px; }
  .sm_mb-3 { margin-bottom: -3px; }
  .sm_m-4 { margin: -4px; }
  .sm_mt-4 { margin-top: -4px; }
  .sm_ml-4 { margin-left: -4px; }
  .sm_mr-4 { margin-right: -4px; }
  .sm_mb-4 { margin-bottom: -4px; }
  .sm_m-5 { margin: -5px; }
  .sm_mt-5 { margin-top: -5px; }
  .sm_ml-5 { margin-left: -5px; }
  .sm_mr-5 { margin-right: -5px; }
  .sm_mb-5 { margin-bottom: -5px; }
  .sm_m-7 { margin: -7px; }
  .sm_mt-7 { margin-top: -7px; }
  .sm_ml-7 { margin-left: -7px; }
  .sm_mr-7 { margin-right: -7px; }
  .sm_mb-7 { margin-bottom: -7px; }
  .sm_m-10 { margin: -10px; }
  .sm_mt-10 { margin-top: -10px; }
  .sm_ml-10 { margin-left: -10px; }
  .sm_mr-10 { margin-right: -10px; }
  .sm_mb-10 { margin-bottom: -10px; }
  .sm_m-13 { margin: -13px; }
  .sm_mt-13 { margin-top: -13px; }
  .sm_ml-13 { margin-left: -13px; }
  .sm_mr-13 { margin-right: -13px; }
  .sm_mb-13 { margin-bottom: -13px; }
  .sm_m-15 { margin: -15px; }
  .sm_mt-15 { margin-top: -15px; }
  .sm_ml-15 { margin-left: -15px; }
  .sm_mr-15 { margin-right: -15px; }
  .sm_mb-15 { margin-bottom: -15px; }
  .sm_m-20 { margin: -20px; }
  .sm_mt-20 { margin-top: -20px; }
  .sm_ml-20 { margin-left: -20px; }
  .sm_mr-20 { margin-right: -20px; }
  .sm_mb-20 { margin-bottom: -20px; }
  .sm_m-25 { margin: -25px; }
  .sm_mt-25 { margin-top: -25px; }
  .sm_ml-25 { margin-left: -25px; }
  .sm_mr-25 { margin-right: -25px; }
  .sm_mb-25 { margin-bottom: -25px; }
  .sm_m-30 { margin: -30px; }
  .sm_mt-30 { margin-top: -30px; }
  .sm_ml-30 { margin-left: -30px; }
  .sm_mr-30 { margin-right: -30px; }
  .sm_mb-30 { margin-bottom: -30px; }

  .sm_p0 { padding: 0 !important; }
  .sm_pt0 { padding-top: 0 !important; }
  .sm_pl0 { padding-left: 0 !important; }
  .sm_pr0 { padding-right: 0 !important; }
  .sm_pb0 { padding-bottom: 0 !important; }
  .sm_p1 { padding: 1px !important; }
  .sm_pt1 { padding-top: 1px !important; }
  .sm_pl1 { padding-left: 1px !important; }
  .sm_pr1 { padding-right: 1px !important; }
  .sm_pb1 { padding-bottom: 1px !important; }
  .sm_p2 { padding: 2px !important; }
  .sm_pt2 { padding-top: 2px !important; }
  .sm_pl2 { padding-left: 2px !important; }
  .sm_pr2 { padding-right: 2px !important; }
  .sm_pb2 { padding-bottom: 2px !important; }
  .sm_p3 { padding: 3px !important; }
  .sm_pt3 { padding-top: 3px !important; }
  .sm_pl3 { padding-left: 3px !important; }
  .sm_pr3 { padding-right: 3px !important; }
  .sm_pb3 { padding-bottom: 3px !important; }
  .sm_p4 { padding: 4px !important; }
  .sm_pt4 { padding-top: 4px !important; }
  .sm_pl4 { padding-left: 4px !important; }
  .sm_pr4 { padding-right: 4px !important; }
  .sm_pb4 { padding-bottom: 4px !important; }
  .sm_p5 { padding: 5px !important; }
  .sm_pt5 { padding-top: 5px !important; }
  .sm_pl5 { padding-left: 5px !important; }
  .sm_pr5 { padding-right: 5px !important; }
  .sm_pb5 { padding-bottom: 5px !important; }
  .sm_p7 { padding: 7px !important; }
  .sm_pt7 { padding-top: 7px !important; }
  .sm_pl7 { padding-left: 7px !important; }
  .sm_pr7 { padding-right: 7px !important; }
  .sm_pb7 { padding-bottom: 7px !important; }
  .sm_p10 { padding: 10px !important; }
  .sm_pt10 { padding-top: 10px !important; }
  .sm_pl10 { padding-left: 10px !important; }
  .sm_pr10 { padding-right: 10px !important; }
  .sm_pb10 { padding-bottom: 10px !important; }
  .sm_p13 { padding: 13px !important; }
  .sm_pt13 { padding-top: 13px !important; }
  .sm_pl13 { padding-left: 13px !important; }
  .sm_pr13 { padding-right: 13px !important; }
  .sm_pb13 { padding-bottom: 13px !important; }
  .sm_p15 { padding: 15px !important; }
  .sm_pt15 { padding-top: 15px !important; }
  .sm_pl15 { padding-left: 15px !important; }
  .sm_pr15 { padding-right: 15px !important; }
  .sm_pb15 { padding-bottom: 15px !important; }
  .sm_p20 { padding: 20px !important; }
  .sm_pt20 { padding-top: 20px !important; }
  .sm_pl20 { padding-left: 20px !important; }
  .sm_pr20 { padding-right: 20px !important; }
  .sm_pb20 { padding-bottom: 20px !important; }
  .sm_p25 { padding: 25px !important; }
  .sm_pt25 { padding-top: 25px !important; }
  .sm_pl25 { padding-left: 25px !important; }
  .sm_pr25 { padding-right: 25px !important; }
  .sm_pb25 { padding-bottom: 25px !important; }
  .sm_p30 { padding: 30px !important; }
  .sm_pt30 { padding-top: 30px !important; }
  .sm_pl30 { padding-left: 30px !important; }
  .sm_pr30 { padding-right: 30px !important; }
  .sm_pb30 { padding-bottom: 30px !important; }

  .sm_fw800, .sm_fw800 *, .sm_black, .sm_black * { font-weight: 800; }
  .sm_fw700, .sm_fw700 *, .sm_bold, .sm_bold * { font-weight: 700; }
  .sm_fw600, .sm_fw600 *, .sm_semibold, .sm_semibold * { font-weight: 600; }
  .sm_fw400, .sm_fw400 * { font-weight: 400; }
  .sm_fw300, .sm_fw300 *, .sm_semilight, .sm_semilight * { font-weight: 300; }

  .sm_tal { text-align: left; }
  .sm_tac { text-align: center; }
  .sm_tar { text-align: right; }

  .sm_vat { vertical-align: top; }
  .sm_vam { vertical-align: middle; }
  .sm_vab { vertical-align: bottom; }
  .sm_vabl { vertical-align: baseline; }

  .sm_fz0 { font-size: 0; }
  .sm_fz0_i { font-size: 0 !important; }
  .sm_fz10, .sm_fz10_a, .sm_fz10_a * { font-size: 10px; }
  .sm_fz10_i, .sm_fz10_a_i, .sm_fz10_a_i * { font-size: 10px !important; }
  .sm_fz11, .sm_fz11_a, .sm_fz11_a * { font-size: 11px; }
  .sm_fz11_i, .sm_fz11_a_i, .sm_fz11_a_i * { font-size: 11px !important; }
  .sm_fz12, .sm_fz12_a, .sm_fz12_a * { font-size: 12px; }
  .sm_fz12_i, .sm_fz12_a_i, .sm_fz12_a_i * { font-size: 12px !important; }
  .sm_fz14, .sm_fz14_a, .sm_fz14_a * { font-size: 14px; }
  .sm_fz14_i, .sm_fz14_a_i, .sm_fz14_a_i * { font-size: 14px !important; }
  .sm_fz15 { font-size: 15px; }
  .sm_fz15_i { font-size: 15px !important; }
  .sm_fz16 { font-size: 16px; }
  .sm_fz16_i { font-size: 16px !important; }
  .sm_fz18 { font-size: 18px; }
  .sm_fz18_i { font-size: 18px !important; }
  .sm_fz20 { font-size: 20px; }
  .sm_fz20_i { font-size: 20px !important; }
  .sm_fz22 { font-size: 22px; }
  .sm_fz22_i { font-size: 22px !important; }
  .sm_fz24 { font-size: 24px; }
  .sm_fz24_i { font-size: 24px !important; }
  .sm_fz26 { font-size: 26px; }
  .sm_fz26_i { font-size: 26px !important; }
  .sm_fz28 { font-size: 28px; }
  .sm_fz28_i { font-size: 28px !important; }
  .sm_fz30 { font-size: 30px; }
  .sm_fz30_i { font-size: 30px !important; }
  .sm_fsi { font-style: italic; }

  .sm_fln { float: none; }
  .sm_fll { float: left; }
  .sm_flr { float: right; }


  .header-content > .wrapper > * + *,
  .header-phones + div {
    padding-left: 10px;
  }

    .header-logo-container {
      min-width: 100px;
    }

    .header-phones a {
      font-size: 1.0833rem;
    }

    .header-phones-container .callback-button {
      padding: 8px;
    }

    .header-content .callback-button:before {
      width: 16px;
      height: 16px;
    }

    .header-menu-button {
      width: 32px;
      height: 32px;
      padding: 6px;
    }
      .header-menu-button span:before {
        top: -7px;
      }
      .header-menu-button span:after {
        bottom: -7px;
      }


  .subheader,
  .main-slide-code-container .subheader {
    font-size: 1.8461rem;
  }
  .subsubheader {
    font-size: 1.5384rem;
  }


  .info-page-image { width: 45%; }


  .list-view {
    margin: -10px 0px 0px -10px;
  }
    .list-view-item {
      width: 100%;
      padding: 10px 0px 0px 10px;
    }


  .banks-block-container .list-view-item {
    width: 50%;
  }


  .filter-dropdown-wrapper {
    margin: -10px 0px 15px 0px ;
  }
    .filter-dropdown-wrapper > div {
      width: 100%;
      padding: 10px 0px 0px 0px;
    }


  .product-chess-section {
    width: 100%;
  }


  .product-page-info {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
    .product-page-left + div {
      padding-top: 20px;
      padding-left: 0px;
    }


  .document-list > div {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 30px;
  }
    .document-list > div + div {
      padding-top: 30px;
    }


  .footer-copyrights {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
    .footer-copyright {
      flex-grow: 0;
    }
      .footer-copyright + .footer-copyright {
        padding-top: 15px;
      }
}

@media screen and (max-width: 399px) {
  .advantages-block-container .list-view-item {
    width: 100%;
  }
    .advantages-block-container .list-view-item-image {
      margin: 0px 18%;
    }
}