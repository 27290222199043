/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  /*text-align: center;*/
  background: #fff;
}

.ffserif { font-family: "Merriweather", "Times New Roman", serif; }

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.lh100p { line-height: 100%; }
.lh110p { line-height: 110%; }
.lh130p { line-height: 130%; }
.lh140p { line-height: 140%; }
.lh150p { line-height: 150%; }
.lh160p { line-height: 160%; }
.lh170p { line-height: 170%; }
.lh180p { line-height: 180%; }
.lh190p { line-height: 190%; }
.lh200p { line-height: 200%; }

.bgptl { background-position: top left; }
.bgptc { background-position: top center; }
.bgptr { background-position: top right; }
.bgpcl { background-position: center left; }
.bgpcc { background-position: center center; }
.bgpcr { background-position: center right; }
.bgpbl { background-position: bottom left; }
.bgpbc { background-position: bottom center; }
.bgpbr { background-position: bottom right; }

.bgrr { background-repeat: repeat; }
.bgrnr { background-repeat: no-repeat; }
.bgrrx { background-repeat: repeat-x; }
.bgrry { background-repeat: repeat-y; }

.bgc0, .bgc0_h:hover { background-color: #000; }
.bgc0_i, .bgc0_h_i:hover { background-color: #000 !important; }
.bgc0_25p, .bgc0_25p_h:hover { background-color: rgba(0,0,0,.25); }
.bgc0_25p_i, .bgc0_25p_h_i:hover { background-color: rgba(0,0,0,.25) !important; }
.bgc0_50p, .bgc0_50p_h:hover { background-color: rgba(0,0,0,.5); }
.bgc0_50p_i, .bgc0_50p_h_i:hover { background-color: rgba(0,0,0,.5) !important; }
.bgc0_75p, .bgc0_75p_h:hover { background-color: rgba(0,0,0,.75); }
.bgc0_75p_i, .bgc0_75p_h_i:hover { background-color: rgba(0,0,0,.75) !important; }
.bgcw, .bgcw_h:hover { background-color: #fff; }
.bgcw_i, .bgcw_h_i:hover { background-color: #fff !important; }
.bgcw_25p, .bgcw_25p_h:hover { background-color: rgba(255,255,255,.25); }
.bgcw_25p_i, .bgcw_25p_h_i:hover { background-color: rgba(255,255,255,.25) !important; }
.bgcw_50p, .bgcw_50p_h:hover { background-color: rgba(255,255,255,.5); }
.bgcw_50p_i, .bgcw_50p_h_i:hover { background-color: rgba(255,255,255,.5) !important; }
.bgcw_75p, .bgcw_75p_h:hover { background-color: rgba(255,255,255,.75); }
.bgcw_75p_i, .bgcw_75p_h_i:hover { background-color: rgba(255,255,255,.75) !important; }
.bgcf6, .bgcf6_h:hover { background-color: #f6f6f6; }
.bgcf6_i, .bgcf6_h_i:hover { background-color: #f6f6f6 !important; }
.bgcea, .bgcea_h:hover { background-color: #eaeaea; }
.bgcea_i, .bgcea_h_i:hover { background-color: #eaeaea !important; }
.bgcc, .bgcc_h:hover { background-color: #ccc; }
.bgcc_i, .bgcc_h_i:hover { background-color: #ccc !important; }
.bgcblue, .bgcblue_h:hover { background-color: #298580; }
.bgcblue_i, .bgcblue_h_i:hover { background-color: #298580 !important; }
.bgcorange, .bgcorange_h:hover { background-color: #d4672b; }
.bgcorange_i, .bgcorange_h_i:hover { background-color: #d4672b !important; }

.c0, .c0_a, .c0_a *, .c0_h:hover, .c0_a_h:hover, .c0_a_h:hover * { color: #000; }
.c0_i, .c0_a_i, .c0_a_i *, .c0_h_i:hover, .c0_a_h_i:hover, .c0_a_h_i:hover * { color: #000 !important; }
.cw, .cw_a, .cw_a *, .cw_h:hover, .cw_a_h:hover, .cw_a_h:hover * { color: #fff; }
.cw_i, .cw_a_i, .cw_a_i *, .cw_h_i:hover, .cw_a_h_i:hover, .cw_a_h_i:hover * { color: #fff !important; }
.cc, .cc_h:hover { color: #ccc; }
.cc_i, .cc_h_i:hover { color: #ccc !important; }
.c90, .c90_h:hover { color: #909090; }
.c90_i, .c90_h_i:hover { color: #909090 !important; }
.c50, .c50_h:hover { color: #505050; }
.c50_i, .c50_h_i:hover { color: #505050 !important; }
label .required, .cred, .cred_h:hover { color: #f33c38; }
.cred_i, .cred_h_i:hover { color: #f33c38 !important; }
.cblue, .cblue_h:hover { color: #298580; }
.cblue_i, .cblue_h_i:hover { color: #298580 !important; }
.corange, .corange_h:hover { color: #d4672b; }
.corange_i, .corange_h_i:hover { color: #d4672b !important; }

.brct, .brct_h:hover { border-color: transparent; }
.brct_i, .brct_h_i:hover { border-color: transparent !important; }
.brc0, .brc0_h:hover { border-color: #000; }
.brc0_i, .brc0_h_i:hover { border-color: #000 !important; }
.brcw, .brcw_h:hover { border-color: #fff; }
.brcw_i, .brcw_h_i:hover { border-color: #fff !important; }
.brcf6, .brcf6_h:hover { border-color: #f6f6f6; }
.brcf6_i, .brcf6_h_i:hover { border-color: #f6f6f6 !important; }
.brcea, .brcea_h:hover { border-color: #eaeaea; }
.brcea_i, .brcea_h_i:hover { border-color: #eaeaea !important; }
.brcc, .brcc_h:hover { border-color: #ccc; }
.brcc_i, .brcc_h_i:hover { border-color: #ccc !important; }
.brc90, .brc90_h:hover { border-color: #909090; }
.brc90_i, .brc90_h_i:hover { border-color: #909090 !important; }
.brc50, .brc50_h:hover { border-color: #505050; }
.brc50_i, .brc50_h_i:hover { border-color: #505050 !important; }
.brcblue, .brcblue_h:hover { border-color: #298580; }
.brcblue_i, .brcblue_h_i:hover { border-color: #298580 !important; }
.brcorange, .brcorange_h:hover { border-color: #d4672b; }
.brcorange_i, .brcorange_h_i:hover { border-color: #d4672b !important; }

.brw1 { border-width: 1px; }
.brtw1 { border-top-width: 1px; }
.brlw1 { border-left-width: 1px; }
.brrw1 { border-right-width: 1px; }
.brbw1 { border-bottom-width: 1px; }
.brw2 { border-width: 2px; }
.brtw2 { border-top-width: 2px; }
.brlw2 { border-left-width: 2px; }
.brrw2 { border-right-width: 2px; }
.brbw2 { border-bottom-width: 2px; }
.brw3 { border-width: 3px; }
.brtw3 { border-top-width: 3px; }
.brlw3 { border-left-width: 3px; }
.brrw3 { border-right-width: 3px; }
.brbw3 { border-bottom-width: 3px; }
.brw5 { border-width: 5px; }
.brtw5 { border-top-width: 5px; }
.brlw5 { border-left-width: 5px; }
.brrw5 { border-right-width: 5px; }
.brbw5 { border-bottom-width: 5px; }
.brw7 { border-width: 7px; }
.brtw7 { border-top-width: 7px; }
.brlw7 { border-left-width: 7px; }
.brrw7 { border-right-width: 7px; }
.brbw7 { border-bottom-width: 7px; }
.brw10 { border-width: 10px; }
.brtw10 { border-top-width: 10px; }
.brlw10 { border-left-width: 10px; }
.brrw10 { border-right-width: 10px; }
.brbw10 { border-bottom-width: 10px; }

.brstn { border-style: none; }
.brsts { border-style: solid; }
.brstd { border-style: dashed; }

.brs2 { border-radius: 2px; }
.brst2 { border-radius: 2px 2px 0px 0px; }
.brsl2 { border-radius: 2px 0px 0px 2px; }
.brsr2 { border-radius: 0px 2px 2px 0px; }
.brsb2 { border-radius: 0px 0px 2px 2px; }
.brs3 { border-radius: 3px; }
.brst3 { border-radius: 3px 3px 0px 0px; }
.brsl3 { border-radius: 3px 0px 0px 3px; }
.brsr3 { border-radius: 0px 3px 3px 0px; }
.brsb3 { border-radius: 0px 0px 3px 3px; }
.brs4 { border-radius: 4px; }
.brst4 { border-radius: 4px 4px 0px 0px; }
.brsl4 { border-radius: 4px 0px 0px 4px; }
.brsr4 { border-radius: 0px 4px 4px 0px; }
.brsb4 { border-radius: 0px 0px 4px 4px; }
.brs5 { border-radius: 5px; }
.brst5 { border-radius: 5px 5px 0px 0px; }
.brsl5 { border-radius: 5px 0px 0px 5px; }
.brsr5 { border-radius: 0px 5px 5px 0px; }
.brsb5 { border-radius: 0px 0px 5px 5px; }
.brs7 { border-radius: 7px; }
.brst7 { border-radius: 7px 7px 0px 0px; }
.brsl7 { border-radius: 7px 0px 0px 7px; }
.brsr7 { border-radius: 0px 7px 7px 0px; }
.brsb7 { border-radius: 0px 0px 7px 7px; }
.brs10 { border-radius: 10px; }
.brst10 { border-radius: 10px 10px 0px 0px; }
.brsl10 { border-radius: 10px 0px 0px 10px; }
.brsr10 { border-radius: 0px 10px 10px 0px; }
.brsb10 { border-radius: 0px 0px 10px 10px; }
.brs50p { border-radius: 50%; }

.dn { display: none; }
.dn_i { display: none !important; }
.db { display: block; }
.dib { display: inline-block; }
.dt { display: table; }
.dtr { display: table-row; }
.dtc { display: table-cell; }
/* Flex */
.dfdr { display: flex; flex-direction: row; }
.dfdrr { display: flex; flex-direction: row-reverse; }
.dfdc { display: flex; flex-direction: column; }
.dfdcr { display: flex; flex-direction: column-reverse; }
.fwn { flex-wrap: nowrap; }
.fww { flex-wrap: wrap; }
.fwwr { flex-wrap: wrap-reverse; }
.fjcs { justify-content: flex-start; }
.fjce { justify-content: flex-end; }
.fjcc { justify-content: center; }
.fjcsa { justify-content: space-around; }
.fjcsb { justify-content: space-between; }
.fais { align-items: flex-start; }
.faie { align-items: flex-end; }
.faic { align-items: center; }
.faib { align-items: baseline; }
.faist { align-items: stretch; }

.curp, .curp_a, .curp_a * { cursor: pointer; }
.curp_i, .curp_a_i, .curp_a_i * { cursor: pointer !important; }
.curd, .curd_a, .curd_a * { cursor: default; }
.curd_i, .curd_a_i, .curd_a_i * { cursor: default !important; }

.h0 { height: 0px; }
.hfull { height: 100%; }
.hauto { height: auto; }

.w0 { width: 0px; }
.w1 { width: 1px; }
.w200 { width: 200px; }
.w230 { width: 230px; }
.w260 { width: 260px; }
.w280 { width: 280px; }
.w320 { width: 320px; }
.w350 { width: 350px; }
.w400 { width: 400px; }
.w480 { width: 480px; }
.w570 { width: 570px; }
.w640 { width: 640px; }
.w700 { width: 700px; }
.w750 { width: 750px; }
.w800 { width: 800px; }
.w860 { width: 860px; }
.w960 { width: 960px; }
.w1024 { width: 1024px; }
.w1048 { width: 1048px; }
.w1100 { width: 1100px; }
.w1200 { width: 1200px; }
.w10p { width: 10%; }
.w12p { width: 12.5%; }
.w15p { width: 15%; }
.w20p { width: 20%; }
.w25p { width: 25%; }
.w33p { width: 33.3333%; }
.w35p { width: 35%; }
.w40p { width: 40%; }
.w50p { width: 50%; }
.w60p { width: 60%; }
.w65p { width: 65%; }
.w66p { width: 66.6666%; }
.w70p { width: 70%; }
.w75p { width: 75%; }
.wfull { width: 100%; }
.wauto { width: auto; }

.m0a { margin: 0 auto; }
.m0a_i { margin: 0 auto !important; }
.ma0 { margin: auto 0; }
.ma0_i { margin: auto 0 !important; }
.ma { margin: auto; }
.ma_i { margin: auto !important; }
.mta { margin-top: auto; }
.mta_i { margin-top: auto !important; }
.mla { margin-left: auto; }
.mla_i { margin-left: auto !important; }
.mra { margin-right: auto; }
.mra_i { margin-right: auto !important; }
.mba { margin-bottom: auto; }
.mba_i { margin-bottom: auto !important; }
.m0 { margin: 0; }
.m0_i { margin: 0 !important; }
.mt0 { margin-top: 0; }
.mt0_i { margin-top: 0 !important; }
.ml0 { margin-left: 0; }
.ml0_i { margin-left: 0 !important; }
.mr0 { margin-right: 0; }
.mr0_i { margin-right: 0 !important; }
.mb0 { margin-bottom: 0; }
.mb0_i { margin-bottom: 0 !important; }
.m1 { margin: 1px; }
.mt1 { margin-top: 1px; }
.ml1 { margin-left: 1px; }
.mr1 { margin-right: 1px; }
.mb1 { margin-bottom: 1px; }
.m2 { margin: 2px; }
.mt2 { margin-top: 2px; }
.ml2 { margin-left: 2px; }
.mr2 { margin-right: 2px; }
.mb2 { margin-bottom: 2px; }
.m3 { margin: 3px; }
.mt3 { margin-top: 3px; }
.ml3 { margin-left: 3px; }
.mr3 { margin-right: 3px; }
.mb3 { margin-bottom: 3px; }
.m4 { margin: 4px; }
.mt4 { margin-top: 4px; }
.ml4 { margin-left: 4px; }
.mr4 { margin-right: 4px; }
.mb4 { margin-bottom: 4px; }
.m5 { margin: 5px; }
.mt5 { margin-top: 5px; }
.ml5 { margin-left: 5px; }
.mr5 { margin-right: 5px; }
.mb5 { margin-bottom: 5px; }
.m7 { margin: 7px; }
.mt7 { margin-top: 7px; }
.ml7 { margin-left: 7px; }
.mr7 { margin-right: 7px; }
.mb7 { margin-bottom: 7px; }
.m10 { margin: 10px; }
.mt10 { margin-top: 10px; }
.ml10 { margin-left: 10px; }
.mr10 { margin-right: 10px; }
.mb10 { margin-bottom: 10px; }
.m13 { margin: 13px; }
.mt13 { margin-top: 13px; }
.ml13 { margin-left: 13px; }
.mr13 { margin-right: 13px; }
.mb13 { margin-bottom: 13px; }
.m15 { margin: 15px; }
.mt15 { margin-top: 15px; }
.ml15 { margin-left: 15px; }
.mr15 { margin-right: 15px; }
.mb15 { margin-bottom: 15px; }
.m20 { margin: 20px; }
.mt20 { margin-top: 20px; }
.ml20 { margin-left: 20px; }
.mr20 { margin-right: 20px; }
.mb20 { margin-bottom: 20px; }
.m-1 { margin: -1px; }
.mt-1 { margin-top: -1px; }
.ml-1 { margin-left: -1px; }
.mr-1 { margin-right: -1px; }
.mb-1 { margin-bottom: -1px; }
.m-2 { margin: -2px; }
.mt-2 { margin-top: -2px; }
.ml-2 { margin-left: -2px; }
.mr-2 { margin-right: -2px; }
.mb-2 { margin-bottom: -2px; }
.m-3 { margin: -3px; }
.mt-3 { margin-top: -3px; }
.ml-3 { margin-left: -3px; }
.mr-3 { margin-right: -3px; }
.mb-3 { margin-bottom: -3px; }
.m-4 { margin: -4px; }
.mt-4 { margin-top: -4px; }
.ml-4 { margin-left: -4px; }
.mr-4 { margin-right: -4px; }
.mb-4 { margin-bottom: -4px; }
.m-5 { margin: -5px; }
.mt-5 { margin-top: -5px; }
.ml-5 { margin-left: -5px; }
.mr-5 { margin-right: -5px; }
.mb-5 { margin-bottom: -5px; }
.m-7 { margin: -7px; }
.mt-7 { margin-top: -7px; }
.ml-7 { margin-left: -7px; }
.mr-7 { margin-right: -7px; }
.mb-7 { margin-bottom: -7px; }
.m-10 { margin: -10px; }
.mt-10 { margin-top: -10px; }
.ml-10 { margin-left: -10px; }
.mr-10 { margin-right: -10px; }
.mb-10 { margin-bottom: -10px; }
.m-13 { margin: -13px; }
.mt-13 { margin-top: -13px; }
.ml-13 { margin-left: -13px; }
.mr-13 { margin-right: -13px; }
.mb-13 { margin-bottom: -13px; }
.m-15 { margin: -15px; }
.mt-15 { margin-top: -15px; }
.ml-15 { margin-left: -15px; }
.mr-15 { margin-right: -15px; }
.mb-15 { margin-bottom: -15px; }
.m-20 { margin: -20px; }
.mt-20 { margin-top: -20px; }
.ml-20 { margin-left: -20px; }
.mr-20 { margin-right: -20px; }
.mb-20 { margin-bottom: -20px; }
.m-25 { margin: -25px; }
.mt-25 { margin-top: -25px; }
.ml-25 { margin-left: -25px; }
.mr-25 { margin-right: -25px; }
.mb-25 { margin-bottom: -25px; }
.m-30 { margin: -30px; }
.mt-30 { margin-top: -30px; }
.ml-30 { margin-left: -30px; }
.mr-30 { margin-right: -30px; }
.mb-30 { margin-bottom: -30px; }

.p0 { padding: 0 !important; }
.pt0 { padding-top: 0 !important; }
.pl0 { padding-left: 0 !important; }
.pr0 { padding-right: 0 !important; }
.pb0 { padding-bottom: 0 !important; }
.p1 { padding: 1px !important; }
.pt1 { padding-top: 1px !important; }
.pl1 { padding-left: 1px !important; }
.pr1 { padding-right: 1px !important; }
.pb1 { padding-bottom: 1px !important; }
.p2 { padding: 2px !important; }
.pt2 { padding-top: 2px !important; }
.pl2 { padding-left: 2px !important; }
.pr2 { padding-right: 2px !important; }
.pb2 { padding-bottom: 2px !important; }
.p3 { padding: 3px !important; }
.pt3 { padding-top: 3px !important; }
.pl3 { padding-left: 3px !important; }
.pr3 { padding-right: 3px !important; }
.pb3 { padding-bottom: 3px !important; }
.p4 { padding: 4px !important; }
.pt4 { padding-top: 4px !important; }
.pl4 { padding-left: 4px !important; }
.pr4 { padding-right: 4px !important; }
.pb4 { padding-bottom: 4px !important; }
.p5 { padding: 5px !important; }
.pt5 { padding-top: 5px !important; }
.pl5 { padding-left: 5px !important; }
.pr5 { padding-right: 5px !important; }
.pb5 { padding-bottom: 5px !important; }
.p7 { padding: 7px !important; }
.pt7 { padding-top: 7px !important; }
.pl7 { padding-left: 7px !important; }
.pr7 { padding-right: 7px !important; }
.pb7 { padding-bottom: 7px !important; }
.p10 { padding: 10px !important; }
.pt10 { padding-top: 10px !important; }
.pl10 { padding-left: 10px !important; }
.pr10 { padding-right: 10px !important; }
.pb10 { padding-bottom: 10px !important; }
.p13 { padding: 13px !important; }
.pt13 { padding-top: 13px !important; }
.pl13 { padding-left: 13px !important; }
.pr13 { padding-right: 13px !important; }
.pb13 { padding-bottom: 13px !important; }
.p15 { padding: 15px !important; }
.pt15 { padding-top: 15px !important; }
.pl15 { padding-left: 15px !important; }
.pr15 { padding-right: 15px !important; }
.pb15 { padding-bottom: 15px !important; }
.p20 { padding: 20px !important; }
.pt20 { padding-top: 20px !important; }
.pl20 { padding-left: 20px !important; }
.pr20 { padding-right: 20px !important; }
.pb20 { padding-bottom: 20px !important; }
.p25 { padding: 25px !important; }
.pt25 { padding-top: 25px !important; }
.pl25 { padding-left: 25px !important; }
.pr25 { padding-right: 25px !important; }
.pb25 { padding-bottom: 25px !important; }
.p30 { padding: 30px !important; }
.pt30 { padding-top: 30px !important; }
.pl30 { padding-left: 30px !important; }
.pr30 { padding-right: 30px !important; }
.pb30 { padding-bottom: 30px !important; }

.fw800, .fw800 *, .black, .black * { font-weight: 800; }
.fw700, .fw700 *, .bold, .bold * { font-weight: 700; }
.fw600, .fw600 *, .semibold, .semibold * { font-weight: 600; }
.fw400, .fw400 * { font-weight: 400; }
.fw300, .fw300 *, .semilight, .semilight * { font-weight: 300; }

.tal { text-align: left; }
.tac { text-align: center; }
.tar { text-align: right; }

.tdn, .tdn_a, .tdn_a *, .tdn_h:hover, .tdn_a_h:hover, .tdn_a_h:hover * { text-decoration: none; }
.tdn_i, .tdn_a_i, .tdn_a_i *, .tdn_h_i:hover, .tdn_a_h_i:hover, .tdn_a_h_i:hover * { text-decoration: none !important; }
.tdu, .tdu_a, .tdu_a *, .tdu_h:hover, .tdu_a_h:hover, .tdu_a_h:hover * { text-decoration: underline; }
.tdu_i, .tdu_a_i, .tdu_a_i *, .tdu_h_i:hover, .tdu_a_h_i:hover, .tdu_a_h_i:hover * { text-decoration: underline !important; }
.tdlth, .tdlth_a, .tdlth_a *, .tdlth_h:hover, .tdlth_a_h:hover, .tdlth_a_h:hover * { text-decoration: line-through; }
.tdlth_i, .tdlth_a_i, .tdlth_a_i *, .tdlth_h_i:hover, .tdlth_a_h_i:hover, .tdlth_a_h_i:hover * { text-decoration: line-through !important; }

.vat { vertical-align: top; }
.vam { vertical-align: middle; }
.vab { vertical-align: bottom; }
.vabl { vertical-align: baseline; }

.fz0 { font-size: 0; }
.fz0_i { font-size: 0 !important; }
.fz10, .fz10_a, .fz10_a * { font-size: 10px; }
.fz10_i, .fz10_a_i, .fz10_a_i * { font-size: 10px !important; }
.fz11, .fz11_a, .fz11_a * { font-size: 11px; }
.fz11_i, .fz11_a_i, .fz11_a_i * { font-size: 11px !important; }
.fz12, .fz12_a, .fz12_a * { font-size: 12px; }
.fz12_i, .fz12_a_i, .fz12_a_i * { font-size: 12px !important; }
.fz14, .fz14_a, .fz14_a * { font-size: 14px; }
.fz14_i, .fz14_a_i, .fz14_a_i * { font-size: 14px !important; }
.fz15 { font-size: 15px; }
.fz15_i { font-size: 15px !important; }
.fz16 { font-size: 16px; }
.fz16_i { font-size: 16px !important; }
.fz18 { font-size: 18px; }
.fz18_i { font-size: 18px !important; }
.fz20 { font-size: 20px; }
.fz20_i { font-size: 20px !important; }
.fz22 { font-size: 22px; }
.fz22_i { font-size: 22px !important; }
.fz24 { font-size: 24px; }
.fz24_i { font-size: 24px !important; }
.fz26 { font-size: 26px; }
.fz26_i { font-size: 26px !important; }
.fz28 { font-size: 28px; }
.fz28_i { font-size: 28px !important; }
.fz30 { font-size: 30px; }
.fz30_i { font-size: 30px !important; }
.fz32 { font-size: 32px; }
.fz32_i { font-size: 32px !important; }
.fz34 { font-size: 34px; }
.fz34_i { font-size: 34px !important; }
.fz36 { font-size: 36px; }
.fz36_i { font-size: 36px !important; }
.fz40 { font-size: 40px; }
.fz40_i { font-size: 40px !important; }
.fz46 { font-size: 46px; }
.fz46_i { font-size: 46px !important; }
.fz48 { font-size: 48px; }
.fz48_i { font-size: 48px !important; }
.fz50 { font-size: 50px; }
.fz50_i { font-size: 50px !important; }
.fz52 { font-size: 52px; }
.fz52_i { font-size: 52px !important; }
.fz54 { font-size: 54px; }
.fz54_i { font-size: 54px !important; }
.fsi { font-style: italic; }

.z0 { z-index: 0; }
.z4 { z-index: 4; }
.z9 { z-index: 9; }
.z99 { z-index: 99; }
.z999 { z-index: 999; }
.z9999 { z-index: 9999; }

.caps { text-transform: uppercase; }

.ofh { overflow: hidden; }

.inherit { font-family: inherit; font-size: inherit; font-weight: inherit; font-style: inherit; text-transform: inherit; }
.fzinherit { font-size: inherit; }

.posr { position: relative; }
.posa { position: absolute; }
.posf { position: fixed; }

.t0 { top: 0px; }
.t100p { top: 100%; }
.t-100p { top: -100%; }
.l0 { left: 0px; }
.l100p { left: 100%; }
.l-100p { left: -100%; }
.r0 { right: 0px; }
.r100p { right: 100%; }
.r-100p { right: -100%; }
.b0 { bottom: 0px; }
.b100p { bottom: 100%; }
.b-100p { bottom: -100%; }

.fll { float: left; }
.flr { float: right; }

p { margin-bottom: 24px; text-align: justify; }
p + p { margin-top: -8px; }

a.link[href] {
  color: #298580;
  text-decoration: underline;
  cursor: pointer;
}
  a.link[href]:hover {
    text-decoration: none;
  }


.form {
  position: relative;
}
  .form-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
    .form-row + .form-row {
      margin-top: 20px;
    }
    .form-buttons {
      margin-top: 30px;
    }
      .form-label label .required {
        font-weight: 700;
      }

      .form-field {
        width: 100%;
        margin-top: 8px;
      }
        .input-container {
          background: #fafafa;
          border: 1px solid #c5c5c5;
          border-radius: 4px;
          padding: 0.78em 1em;
          position: relative;
          z-index: 1;
        }
          .input-container.error {
            border-color: #ff0f26;
          }

          .input-container input,
          .input-container textarea,
          .input-container select {
            background: transparent;
            border: 0;
            width: 100%;
            min-width: 100%;
          }

        .form-error {
          position: relative;
        }
          .errorMessage {
            color: #fff;
            background: #ff0f26;
            border-radius: 3px;
            font-size: 0.8666em;
            line-height: 1.3;
            padding: 3px 6px;
            margin: 2px 0px 0px;
          }

  .form-button {
    display: inline-block;
  }


.button-style {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  color: #fff;
  background: #298580;
  border: 0;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  padding: 1em 1.4em;
  border-radius: 4px;
  text-align: center;
  position: relative;
  cursor: pointer;

  -webkit-transition: color .15s ease 0s, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
  -moz-transition: color .15s ease 0s, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
  -o-transition: color .15s ease 0s, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
  transition: color .15s ease 0s, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
}
  .button-style:hover {
    background: #167074;
  }
  .button-style:active {
    box-shadow: inset 0px 3px 5px rgba(0,0,0,.125);
  }

  .button-style.button-more {
    background: #fff;
    border: 2px solid #ccc;
    color: #999;
  }
    .button-style.button-more:hover {
      border: 2px solid #999;
      color: #666;
    }


ul.ul { list-style: disc outside; padding-left: 25px; }
ul.ul, ol.ol {
  margin-top: -6px;
  margin-bottom: 20px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 20px;
    margin-top: 0px;
    margin-bottom: 2px;
  }
  ul.ul li, ol.ol li {
    padding-top: 6px;
    padding-bottom: 0px;
    position: relative;
  }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      font-weight: 600;
      margin-right: 0.6em;
      color: #298580;
    }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:before {
    content: "";
    background: url('/i/gallery-image-zoom.svg') 100% 100% no-repeat transparent;
    background-size: contain;
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 8%;
    height: 8%;
    z-index: 1;

    -webkit-transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s;
    -moz-transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s;
    -o-transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s;
    transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s;
  }
    a.zoom-image:hover:before {
      width: 10%;
      height: 10%;
    }


.hr {
  font-size: 0px;
  box-sizing: content-box;
  padding: 40px 0px;
}
  .hr:before {
    content: "";
    display: block;
    background: #ccc;
    height: 1px;
  }
  .hr.hr-min {
    padding-top: 30px;
  }


.wrapper {
  width: 1204px;
  position: relative;
  margin: 0px auto;
  padding: 0px;
}
  .unwrapper {
    margin-left: -9999px;
    margin-right: -9999px;
    position: static;
  }



/* Header */
.header {
  position: relative;
  z-index: 5;
}
  .header-content {
    padding: 20px 0px;
    box-shadow: 0px 0px 5px rgba(0,0,0,.5);
    position: relative;
    z-index: 1;
  }
    .header-content > .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
      .header-content > .wrapper > * + * {
        padding-left: 20px;
      }

      .header-logo-container {
        min-width: 200px;
      }
        .header-logo-container > a {
          cursor: pointer;
          text-decoration: none;
        }
        .header-logo-container div {
          font-size: 0px;
        }
          .header-logo-container img {
            width: 100%;
          }


      .header-phones-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
        .header-phones + div {
          padding-left: 20px;
        }
          .header-phones a {
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 1;
            text-decoration: none;
            white-space: nowrap;
            cursor: default;
          }


      .header-social-container,
      .contact-social-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        margin: -10px 0px 0px -10px;
      }
        .header-social-container {
          min-width: 108px;
        }
        .contact-social-container {
          padding-top: 25px;
        }

        .header-social-container > div,
        .contact-social-container > div {
          padding: 10px 0px 0px 10px;
        }
          .header-social-container a,
          .contact-social-container a {
            content: "";
            display: block;
            color: transparent;
            font-size: 0px;
            width: 34px;
            height: 34px;
            cursor: pointer;
            position: relative;
          }
            .contact-social-container a {
              width: 40px;
              height: 40px;
            }

            .header-social-container a:before,
            .header-social-container a:after,
            .contact-social-container a:before,
            .contact-social-container a:after {
              content: "";
              border-radius: 6px;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
            }
            .header-social-container a:before,
            .contact-social-container a:before {
              border: 1px solid #c5c5c5;
            }
            .header-social-container a:after,
            .contact-social-container a:after {
              background-color: transparent;
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: contain;
              z-index: 1;
            }
              .header-social-container .social-icon-vk a:after,
              .contact-social-container .social-icon-vk a:after {
                background-image: url(/f/upload/social-icon-vk-header-min.svg);
              }
              .header-social-container .social-icon-fb a:after,
              .contact-social-container .social-icon-fb a:after {
                background-image: url(/f/upload/social-icon-fb-header-min.svg);
              }
              .header-social-container .social-icon-instagram a:after,
              .contact-social-container .social-icon-instagram a:after {
                background-image: url(/f/upload/social-icon-instagram-header-min.svg);
              }
              .header-social-container .social-icon-twitter a:after,
              .contact-social-container .social-icon-twitter a:after {
                background-image: url(/f/upload/social-icon-twitter-header-min.svg);
              }


      .header-content .callback-button {
        white-space: nowrap;
      }


      .header-menu-button-container,
      .header-menu-checkbox {
        display: none;
      }


  .header-menu-container {
    background: #298580;
    padding: 0px;
  }
    .header-fixed .header-menu-container {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      box-shadow: 0px 0px 5px rgba(0,0,0,.5);
    }

    .header-menu-close-button {
      display: none;
    }

    .header-menu-container ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
      .header-menu-container li {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        position: relative;
      }
        .header-menu-container .dropmarker {
          display: none;
        }

        .header-menu-container li > div > div > * {
          display: block;
          color: #fff;
          font-size: 0.9333rem;
          text-transform: uppercase;
          white-space: nowrap;
          padding: 1.25em;
          text-align: center;
        }
        .header-menu-container li > div a {
          cursor: pointer;
        }
        .header-menu-container li > div span,
        .header-menu-container li.active > div > div > * {
          cursor: default;
        }
          .header-menu-container li > div > div:hover > *,
          .header-menu-container li.active > div > div > * {
            background: #167074;
          }


        .header-menu-container li > ul {
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          background: #fff;
          border: 1px solid #c5b99f;
          box-shadow: 0px 2px 5px 0px rgba(0,0,0,.25);
          position: absolute;
          top: 100%;
          left: 0px;
          visibility: hidden;
        }
          .header-menu-container li:hover > ul {
            visibility: visible;
          }

          .header-menu-container li > ul li > div > div > * {
            color: #333;
            text-align: left;
            padding: 0.6em 1em;
          }
            .header-menu-container li > ul li > div > div:hover > *,
            .header-menu-container li > ul li.active > div > div > * {
              color: #fff;
            }




/* Content block */
.content {
  padding: 0px 0px 50px;
  position: relative;
  z-index: 3;

  /*min-height: 3000px;*/
}
  .main-rotator-block-container {
    margin: 0px 0px 10px;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
    .main-rotator-container {
      /*max-height: 500px;*/
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
      .main-rotator-slide-container {
        position: relative;
      }
        .main-rotator-slide-image {
          position: relative;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-color: #eaeaea;
          background-size: cover;
          font-size: 0px;
          /*max-height: 500px;*/
        }
          .main-rotator-slide-image:before {
            content: "";
            display: block;
            font-size: 0px;
            height: 0px;
            padding-top: 45.41666667%;
          }

        .main-slide-code-container {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
        }
          .main-slide-code-container > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            width: 100%;
            max-width: 1204px;
            height: 100%;
            margin: 0px auto;
          }
            .main-slide-code-container > div > div {
              background: rgba(255,255,255,.7);
              /*width: 100%;*/
              /*max-width: 500px;*/
              border-radius: 2px;
              padding: 20px 25px;
              margin: 0px 0px 20px;
              overflow: hidden;
              position: relative;
            }

              .main-slide-code-container .subheader {
                font-size: 2.6666rem;
              }
              .main-slide-code-container .subheader-description {
                font-size: 1.2rem;
              }

              .main-slide-code-container .button-style {
                border: 2px solid #fff;
              }
                .main-slide-code-container .button-style:hover {
                  box-shadow: 0px 3px 7px 0px rgba(0,0,0,.35);
                }
                .main-slide-code-container .button-style:active {
                  box-shadow: inset 0px 3px 5px rgba(0,0,0,.125);
                }


      .main-rotator-dots {
        position: relative;
        z-index: 1;
      }
        .main-rotator-dots ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: center;
          margin: 0px 0px 0px -8px;
        }
          .main-rotator-dots li {
            width: 101px;
            padding: 8px 0px 0px 8px;
            cursor: pointer;
          }
            .main-rotator-dots li div {
              background: #ccc;
              height: 6px;
              transition: background .15s ease;
              cursor: pointer;
            }
              .main-rotator-dots li:hover div {
                background: #999;
              }
              .main-rotator-dots li.slick-active div {
                background: #298580;
              }


  .content-inner {
    padding-top: 30px;
    overflow: hidden;
    position: relative;
  }
    .breadcrumbs {
      font-size: 0px;
      padding: 0px 0px 10px;
    }
      .breadcrumbs li {
        display: inline;
        font-size: 0px;
        vertical-align: baseline;
      }
        .breadcrumbs li:before,
        .breadcrumbs a,
        .breadcrumbs span {
          font-size: 12px;
          vertical-align: baseline;
          line-height: 1.1;
        }
        .breadcrumbs li + li:before {
          content: "→";
          font-size: 13px;
          display: inline-block;
          margin: 0px 5px;
        }
        .breadcrumbs a {
          color: #298580;
          cursor: pointer;
          text-decoration: underline;
        }
          .breadcrumbs a:hover {
            text-decoration: none;
          }


    .subheader,
    .subsubheader {
      color: #000;
      position: relative;
      font-family: "Merriweather", "Times New Roman", serif;
      font-weight: 300;
      line-height: 1.2;
      padding: 0.3em 0em 0.4em;
      text-align: center;
      z-index: 2;
    }
    .subheader {
      font-size: 3rem;
    }

    .subheader-description {
      color: #666;
      font-family: "Merriweather", "Times New Roman", serif;
      font-size: 1.3333rem;
      font-weight: 300;
      text-align: center;
      padding: 0em 0em 1em;
    }
      .subheader + .subheader-description {
        margin-top: -0.4em;
      }

    .subsubheader {
      font-size: 2.2rem;
      padding: 0em 0em 0.6888em;
      text-align: left;
    }


    .section-inner {
      padding-top: 35px;
    }


    .scrollable {
      width: 100%;
      overflow: auto;
    }


    .text-container {
      padding: 0px 0px 30px;
      position: relative;
      z-index: 1;
    }


    .info-page-image {
      float: left;
      padding: 0px 30px 25px 0px;
      width: 330px;
    }
      .info-page-image img {
        width: 100%;
      }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      padding: 0px 0px 20px;
      margin: -32px 0px 0px -32px;
      position: relative;
    }
      .gallery-block-container .gallery-item {
        padding: 32px 0px 0px 32px;
        position: relative;
      }
        .gallery-item.gallery-item-width-1 { width: 1236px; }
        .gallery-item.gallery-item-width-2 { width: 618px; }
        .gallery-item.gallery-item-width-3 { width: 412px; }
        .gallery-item.gallery-item-width-4 { width: 309px; }

        .gallery-block-container .gallery-item-notice {
          font-size: 0.9333rem;
          padding: 15px 0px 0px;
          text-align: center;
        }

      .gallery-block-container .gallery-notice {
        padding: 30px 0 22px 32px;
        position: relative;
      }


    .document-list > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 70px;
    }
      .document-list > div + div {
        border-top: 1px solid #ccc;
        padding-top: 70px;
      }

      .document-list > div > div:first-child {
        font-size: 1.5333rem;
        padding: 15px;
        min-width: 33.3333%;
      }

      .document-list > div > div + div {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        width: 100%;
        padding: 0px 15px 15px 0px;
      }
        .document-list > div > div + div > div {
          width: 50%;
          padding: 15px;
        }

        .document-list > div > div + div a {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          cursor: pointer;
          padding-left: 75px;
          position: relative;
          min-height: 50px;
        }
          .document-list > div > div + div a:before {
            content: "";
            position: absolute;
            background-size: contain;
            top: 0px;
            left: 0px;
            width: 36px;
            height: 36px;
            border: 7px solid transparent;

            -webkit-transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
            -moz-transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
            -o-transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
            transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
          }
            .document-list > div > div + div .icon-pdf > a:before {
              background: url('/f/upload/file-icon--pdf.svg') no-repeat 50% 50% #b00606;
              border-color: #b00606;
            }
              .document-list > div > div + div .icon-pdf > a:hover:before {
                background-color: #7b0404;
                border-color: #7b0404;
              }
            .document-list > div > div + div .icon-docx > a:before {
              background: url('/f/upload/file-icon--docx.svg') no-repeat 50% 50% #2c5898;
              border-color: #2c5898;
            }
              .document-list > div > div + div .icon-docx > a:hover:before {
                background-color: #1f3d6a;
                border-color: #1f3d6a;
              }

          .document-list > div > div + div a span:first-child {
            font-size: 1.0666rem;
            text-decoration: none;
          }
            .document-list > div > div + div a:hover span:first-child {
              text-decoration: underline;
            }

          .document-list > div > div + div a span + span {
            color: #999;
            font-size: 0.8666rem;
            line-height: 1.1;
            margin-top: 0.9em;
          }


    .object-map-container {
      position: relative;
      margin: 0px 0px 30px;
    }
      .object-map-container:before {
        content: "";
        display: block;
        height: 0px;
        padding-top: 45%;
        z-index: 0;
      }
      .object-map-container:after {
        content: "Загрузка карты...";
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1;
      }

      .object-map-container > div {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 2;
      }


    #filter-container {
      padding: 0px 0px 20px;
      position: relative;
      z-index: 3;
    }
      .list-view-skin-mode {
        padding: 0px 0px 20px;
      }
        .list-view-skin-mode .filter-caption {
          display: none;
        }

        .list-view-skin-buttons {
          margin: 0px 0px 0px -20px;
        }
          .list-view-skin-buttons .button-style {
            font-size: 1.1rem;
            padding: 0.8em 1.2em;
            margin: 0px 0px 0px 20px;
          }
          .list-view-skin-buttons .button-style.active {
            background: #298580;
            color: #fff;
            border-color: transparent;
            cursor: default;
          }


      .filter-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
        .filter-elements-container {
          width: 100%;
        }
          .filter-dropdown-wrapper {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
            margin: -15px 30px 0px -30px;
          }
            .filter-dropdown-wrapper > div {
              width: 33.3333%;
              padding: 15px 0px 0px 30px;
              position: relative;
              z-index: 0;
            }
              .filter-dropdown-wrapper > div.active {
                z-index: 1;
              }

              .filter-dropdown-button {
                background: #f9f8f5;
                font-size: 0.9333rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                border: 1px solid #ccc;
                padding: 0.5em 40px 0.5em 0.85em;
                vertical-align: baseline;
                overflow: hidden;
                cursor: pointer;
                position: relative;
                z-index: 0;
              }
                .filter-dropdown-button:before {
                  content: "";
                  border-top: 8px solid #89826c;
                  border-left: 8px solid transparent;
                  border-right: 8px solid transparent;
                  position: absolute;
                  top: 0px;
                  bottom: 0px;
                  right: 12px;
                  height: 0px;
                  width: 0px;
                  margin: auto;
                }

                .filter-dropdown-button.selected {
                  border: 2px solid #298580;
                  margin: -1px;
                }

                .filter-dropdown-button .filter-caption {
                  font-size: 0.9333rem;
                  vertical-align: baseline;
                }

              .filter-dropdown-container {
                display: none;
                position: absolute;
                top: 100%;
                left: 30px;
                right: 0px;
                padding: 14px 0px 0px;
                z-index: 1;
              }
                .filter-dropdown-wrapper > div.active .filter-dropdown-container {
                  display: block;
                }

                .filter-dropdown-container:before,
                .filter-dropdown-container:after {
                  content: "";
                  position: absolute;
                  border-left: 9px solid transparent;
                  border-right: 9px solid transparent;
                  border-bottom: 9px solid #c5b99f;
                  left: 0px;
                  right: 0px;
                  margin: auto;
                  width: 0px;
                  height: 0px;
                }
                .filter-dropdown-container:before {
                  top: 6px;
                  z-index: 1;
                }
                .filter-dropdown-container:after {
                  top: 8px;
                  border-bottom-color: #fff;
                  z-index: 2;
                }

                .filter-dropdown-container .filter-dropdown {
                  padding: 15px;
                  background: #fff;
                  border: 1px solid #c5b99f;
                  box-shadow: 0px 2px 5px 0px rgba(0,0,0,.25);
                }
                  .filter-slider-container {
                    padding: 34px 10px 0px;
                    position: relative;
                  }
                    .filter-slider-container > .ui-slider {
                      height: 10px;
                      background: #eee;
                      background: linear-gradient(to bottom, #ddd -50%, #fff 150%);
                      border: 1px solid #ccc;
                      border-radius: 99px;
                      outline: none;
                      position: relative;
                    }
                      .filter-slider-container .ui-slider-range {
                        background: #298580;
                        border-radius: 9px;
                        position: absolute;
                        top: 0px;
                        bottom: 0px;
                      }

                      .filter-slider-container .ui-slider-handle {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto auto auto -10px;
                        width: 20px;
                        height: 20px;
                        background: #fff;
                        border: 1px solid #298580;
                        border-radius: 50%;
                        cursor: pointer;
                      }
                        .filter-slider-container .ui-slider-handle:hover {
                          z-index: 1;
                        }

                        .filter-slider-container .ui-slider-handle > span {
                          display: flex;
                          flex-direction: row;
                          justify-content: center;
                          align-items: center;
                          position: absolute;
                          left: 0px;
                          right: 0px;
                          bottom: 100%;
                          padding: 0px 0px 5px;
                        }
                          .filter-slider-container .ui-slider-handle > span > span {
                            display: block;
                            color: #fff;
                            font-size: 0.9333rem;
                            text-shadow: none;
                            padding: 1px 5px;
                            background: #298580;
                            border: 2px solid #fff;
                            border-radius: 5px;
                            margin: 0px auto;
                            cursor: default;
                            white-space: nowrap;
                            outline: none;
                          }


                  .filter-slider-inputs {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin: 20px 0px 0px -12px;
                  }
                    .filter-slider-inputs > * {
                      margin: 0px 0px 0px 12px;
                    }

                    .filter-slider-inputs .input-container {
                      border-radius: 0px;
                      padding: 0.35em 0.5em;
                    }


                  .filter-dropdown-container .filter-dropdown .filter-checkbox-list {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    align-content: flex-start;
                    align-items: flex-start;
                    margin: -8px 0px 0px -8px;
                  }
                    .filter-dropdown-container .filter-dropdown .filter-checkbox-list > div {
                      font-size: 0px;
                      width: 50%;
                      padding: 8px 0px 0px 8px;
                    }
                      .filter-dropdown-container .filter-dropdown .filter-checkbox-list > div input {
                        display: none;
                      }
                      .filter-dropdown-container .filter-dropdown .filter-checkbox-list > div label {
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 0.9333rem;
                        line-height: 1;
                        width: 100%;
                        cursor: pointer;
                      }
                        .filter-dropdown-container .filter-dropdown .filter-checkbox-list > div label span {
                          width: 20px;
                          min-width: 20px;
                          height: 20px;
                          background: #fff;
                          border: 1px solid #ddd;
                          margin-right: 8px;
                          font-size: 0;
                          position: relative;
                        }
                          .filter-dropdown-container .filter-dropdown .filter-checkbox-list > div label:hover span,
                          .filter-dropdown-container .filter-dropdown .filter-checkbox-list > div input:checked + label span {
                            border-color: #298580;
                          }

                          .filter-dropdown-container .filter-dropdown .filter-checkbox-list > div input:checked + label span:before {
                            content: "";
                            background: url('/i/filter-checked-icon.svg') no-repeat 50% 50% transparent;
                            background-size: contain;
                            position: absolute;
                            top: 1px;
                            left: 3px;
                            right: 3px;
                            bottom: 1px;
                          }


        .filter-buttons-container > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          min-width: 200px;
        }
          .filter-buttons-container > div > div + div {
            padding: 15px 0px 0px;
            }
            .filter-button-submit,
            .filter-button-submit .button-style {
              width: 100%;
            }
              .filter-button-submit .button-style {
                font-size: 1.1rem;
                padding: 0.8em 1.2em;
              }

            .filter-button-reset a {
              color: #999;
              font-size: 1.1rem;
              font-weight: 600;
              text-decoration: underline;
            }
            .filter-button-reset a:hover {
              text-decoration: none;
            }


    .list-view {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;

      padding: 0px 0px 20px;
      margin: -35px 0px 0px -35px;
      position: relative;
    }
      .list-view-item {
        width: 33.3333%;
        padding: 35px 0px 0px 35px;
        position: relative;
      }
        .list-view-item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          width: 100%;
          height: 100%;
          box-shadow: 0px 1px 4px 0px rgba(0,0,0,.25);
          position: relative;
        }
          .list-view-item-image {
            background: #eaeaea;
            font-size: 0px;
            position: relative;
            order: 1;
            z-index: 1;
          }
            .list-view-item-image a,
            .list-view-item-image span {
              display: block;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: cover;
              font-size: 0px;
              height: 0px;
              padding-top: 75%;
            }
              .list-view-item-image a {
                cursor: pointer;
              }

          .list-view-item-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            height: 100%;
            padding: 20px 25px 28px;
            position: relative;
            order: 2;
            z-index: 2;
          }
            .list-view-item-name {
              position: relative;
            }
              .list-view-item-name a,
              .list-view-item-name span {
                font-size: 1.6rem;
                text-decoration: none;
              }
                .list-view-item-name a {
                  color: #298580;
                  text-decoration: underline;
                  cursor: pointer;
                }
                  .list-view-item-name a:hover,
                  .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                    text-decoration: none;
                  }

            .list-view-item-name + .list-view-item-notice {
              padding: 20px 0px 0px;
            }
              .list-view-item-notice * {
                font-size: 0.9333rem;
                text-align: left;
              }
              .list-view-item-notice *:last-child {
                margin-bottom: 0px;
              }


            .list-view-item-parameters {
              padding: 15px 0px 0px;
            }
              .list-view-item-parameters > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              }
                .list-view-item-parameters > div + div {
                  margin-top: 8px;
                }
                .list-view-item-parameters span {
                  font-size: 0.9333rem;
                }
                  .list-view-item-parameters span + span {
                    color: #999;
                  }



            .news-block-container .list-view-item-image a,
            .news-block-container .list-view-item-image span {
              padding-top: 52%;
            }

            .news-block-container .list-view-item-name a,
            .news-block-container .list-view-item-name span {
              font-size: 1.0666rem;
            }

            .news-item-date time {
              color: #888;
            }

            .news-item-house {
              margin-top: 0.45em;
            }


    .advantages-block-container .list-view {
      justify-content: center;
      margin: -20px 0px 0px -30px;
    }
      .advantages-block-container .list-view-item {
        width: 25%;
        padding: 20px 0px 0px 30px;
      }
        .advantages-block-container .list-view-item-wrapper {
          box-shadow: none;
        }
          .advantages-block-container .list-view-item-image {
            border: 2px solid #298580;
            border-radius: 50%;
            margin: 0px 30px;
            overflow: hidden;
          }
            .advantages-block-container .list-view-item-image span {
              padding-top: 100%;
            }

          .advantages-block-container .list-view-item-text {
            justify-content: flex-start;
            height: auto;
          }
            .advantages-block-container .list-view-item-name {
              font-size: 18px;
              font-weight: 600;
              text-align: center;
            }

            .advantages-block-container .list-view-item-notice {
              text-align: center;
              padding: 15px 0px 0px;
            }


    .banks-block-container .list-view {
      justify-content: space-around;
      margin-left: -60px;
    }
      .banks-block-container .list-view-item {
        width: 25%;
        padding-left: 60px;
      }
        .banks-block-container .list-view-item-wrapper {
          box-shadow: none;
        }
          .banks-block-container .list-view-item-image {
            background: none;
            margin: 0px 21px;
          }
            .banks-block-container .list-view-item-image span {
              background-size: contain;
              padding-top: 65%;
            }

          .banks-block-container .list-view-item-text {
            justify-content: flex-start;
            padding: 12px 15px 15px;
          }
            .banks-block-container .list-view-item-name {
              font-size: 1.0666rem;
              font-weight: 700;
              text-align: center;
            }
            .banks-block-container .list-view-item-notice {
              text-align: center;
              padding: 12px 0px 0px;
              vertical-align: baseline;
            }
              .banks-block-container .list-view-item-notice span {
                font-weight: 600;
                vertical-align: baseline;
              }

          .bank-item-button {
            text-align: center;
            padding: 12px 0px 0px;
            order: 3;
            z-index: 3;
          }
            .bank-item-button .button-style {
              font-size: 1.0666rem;
              padding: 0.75em 1.25em;
            }


    .product-chess-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-end;
      margin: -20px 0px 0px -30px;
    }
      .product-chess-container.is-hovered {
        background: #fff;
        position: relative;
        z-index: 5;
      }

      .product-chess-section {
        padding: 20px 0px 0px 30px;
        min-width: 35%;
      }
        .product-chess-section-name {
          text-align: center;
        }
          .product-chess-section-name > * {
            display: inline-block;
            font-size: 1.8rem;
            text-decoration: none;
            padding: 0em 0em 0.4em;
          }
            .product-chess-section-name > a:hover {
              color: #298580;
            }

        .product-chess-floors > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          background: #f0f0f0;
        }
        .product-chess-floors > div + div {
          margin-top: -14px;
        }
          .product-chess-floors > div > div {
            padding-bottom: 14px;
          }
            .product-chess-floors > div:first-child > div {
              padding-top: 10px;
            }

          .product-chess-floor-label {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            color: #999;
            background: #fff;
            font-size: 0.8rem;
            font-weight: 600;
            white-space: nowrap;
            min-width: 85px;
            padding-right: 25px;
            text-align: right;
          }
            .product-chess-floors > div + div .product-chess-floor-label {
              padding-top: 4px;
            }

          .product-chess-floor-label + div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-left: 10px;
            padding-right: 14px;
          }
            .product-chess-flat {
              width: 100%;
              min-width: 22px;
              min-height: 20px;
              max-height: 32px;
              padding: 4px 0px 0px 4px;
              position: relative;
            }
              .product-chess-flat > a,
              .product-chess-flat > span {
                display: block;
                color: transparent;
                font-size: 0px;
                padding: 45% 0%;
                height: 0px;
                cursor: pointer;
              }
                .product-chess-flat.disabled > a,
                .product-chess-flat.disabled > span {
                  cursor: default;
                }

                .product-chess-flat > a:before,
                .product-chess-flat > span:before {
                  content: attr(data-value);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #298580;
                  border: 1px solid transparent;
                  color: #fff;
                  font-size: 0.8rem;
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  right: 0px;
                  bottom: 0px;
                  z-index: 2;
                }
                  .product-chess-flat.disabled > a:before,
                  .product-chess-flat.disabled > span:before {
                    color: #666;
                    background: #e2e2e2;
                    border-color: #ddd;
                  }

                .product-chess-flat.with-design > a:after,
                .product-chess-flat.with-design > span:after {
                  content: "";
                  background: #d8a98f;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: -4px;
                  bottom: -4px;
                  z-index: 1;
                }


              .product-chess-flat-details {
                display: none;
                position: absolute;
                bottom: 100%;
                left: 4px;
                right: 0px;
                height: 0px;
                margin: auto;
                z-index: 5;
              }
                .product-chess-flat.details-initialized:hover .product-chess-flat-details {
                  display: block;
                }

                .product-chess-flat-details:before,
                .product-chess-flat-details:after {
                  content: "";
                  border-top: 7px solid #c5b99f;
                  border-left: 7px solid transparent;
                  border-right: 7px solid transparent;
                  position: absolute;
                  left: 0px;
                  right: 0px;
                  width: 0px;
                  height: 0px;
                  margin: auto;
                }
                .product-chess-flat-details:before {
                  bottom: -8px;
                  z-index: 1;
                }
                .product-chess-flat-details:after {
                  bottom: -7px;
                  border-top-color: #fff;
                  z-index: 2;
                }

                .product-chess-flat-details > div {
                  display: flex;
                  justify-content: center;
                  align-items: flex-end;
                  position: absolute;
                  left: 0px;
                  right: 0px;
                  width: 0px;
                  bottom: -2px;
                  margin: 0px auto;
                }
                  .product-chess-flat:nth-last-child(1) .product-chess-flat-details > div,
                  .product-chess-flat:nth-last-child(2) .product-chess-flat-details > div {
                    justify-content: flex-end;
                    left: auto;
                  }
                    .product-chess-flat:nth-last-child(1) .product-chess-flat-details > div > div {
                      margin-right: -15px;
                    }
                    .product-chess-flat:nth-last-child(2) .product-chess-flat-details > div > div {
                      margin-right: -38px;
                    }

                  .product-chess-flat:nth-child(1) .product-chess-flat-details > div,
                  .product-chess-flat:nth-child(2) .product-chess-flat-details > div {
                    justify-content: flex-start;
                    right: auto;
                  }
                    .product-chess-flat:nth-child(1) .product-chess-flat-details > div > div {
                      margin-left: -15px;
                    }
                    .product-chess-flat:nth-child(2) .product-chess-flat-details > div > div {
                      margin-left: -38px;
                    }

                  .product-chess-flat-details > div > div {
                    position: relative;
                    padding: 8px;
                    background: #fff;
                    border: 1px solid #c5b99f;
                    box-shadow: 0px -2px 5px -1px rgba(0,0,0,.25);
                  }
                    .product-chess-flat-details .flat-image {
                      background: url('/i/ajax/ajax.gif') no-repeat 50% 50% transparent;
                    }
                      .product-chess-flat.details-initialized .product-chess-flat-details .flat-image {
                        background: none;
                      }

                      .product-chess-flat-details .flat-image img {
                        width: 100%;
                      }

                    .product-chess-flat-details .flat-name a {
                      display: block;
                      font-size: 0.9333rem;
                      font-weight: 700;
                      line-height: 1.1;
                      padding: 0.5em 0em;
                    }

                    .product-chess-flat-details .flat-name + div {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: flex-start;
                      padding: 0px 0px 8px;
                    }
                      .product-chess-flat-details .flat-name + div > span {
                        font-size: 0.8666rem;
                        line-height: 1.1;
                        white-space: nowrap;
                      }
                      .product-chess-flat-details .flat-name + div > span + span {
                        padding-left: 20px;
                      }
                        .product-chess-flat-details .flat-name + div > span + span .price-old {
                          color: #999;
                          display: inline-block;
                          text-decoration: line-through;
                          padding: 0px 0px 2px;
                        }
                        .product-chess-flat-details .flat-name + div > span + span .price-old ~ .price {
                          color: #f33c38;
                          font-weight: 600;
                        }

                    .product-chess-flat-details .button-style {
                      font-size: 0.7rem;
                      width: 100%;
                      padding: 0.9em 1.2em;
                    }


    .list-view-line {
      padding: 0px 0px 20px;
    }
      .table-style {
        width: 100%;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
        .table-style th,
        .table-style td {
          text-align: center;
          vertical-align: middle;
          padding: 12px 20px;
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
        }
          .table-style th.tal,
          .table-style td.tal {
            text-align: left;
          }
          .table-style th.tar,
          .table-style td.tar {
            text-align: right;
          }

          .table-style th span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: 600;
            width: 100%;
            min-height: 45px;
          }

        .table-style th,
        .table-style .product-line-type {
          background: #f7f7f7;
        }
        .table-style .product-line-type {
          width: 170px;
        }
          .product-line-type-image {
            display: block;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            padding-top: 75%;
            margin: auto;
          }

          .product-line-type-name {
            display: block;
            line-height: 1;
            text-align: center;
            margin: 10px 0px 0px;
          }

          .product-line-price .price-old {
            color: #999;
            display: inline-block;
            text-decoration: line-through;
            padding: 0px 0px 2px;
          }
          .product-line-price .price-old ~ .price {
            color: #f33c38;
            font-weight: 600;
          }


      /*.table-style tr:first-child ~ tr { display: none !important; }*/
      .table-style + .pager-block-container ul {
        display: none;
      }
        .table-style + .pager-block-container .ias_trigger,
        .table-style + .pager-block-container .ias_loader {
          text-align: center;
          padding: 35px 0px 0px;
        }
          .table-style + .pager-block-container .ias_trigger .button-style,
          .table-style + .pager-block-container .ias_loader .button-style {
            font-size: 1.1rem;
            padding: 0.8em 1.2em;
          }

          .table-style + .pager-block-container .ias_loader .button-style {
            color: transparent;
          }
            .table-style + .pager-block-container .ias_loader .button-style:before {
              content: "";
              background: url('/i/ajax/ajax.gif') no-repeat 50% 50%;
              background-size: contain;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              margin: auto;
              height: 32px;
            }


    .product-page-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
      .product-page-left {
        min-width: 50%;
      }
        .product-page-actions-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background: #f6f6f6;
          padding: 20px 40px;
        }
          .product-page-actions-container > a {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
          }
            .product-page-actions-container > a:before {
              content: "";
              background: url('/i/product-action-print-icon.svg') no-repeat 50% 50% transparent;
              background-size: contain;
              width: 22px;
              height: 22px;
            }

          .product-page-actions-container > a span {
            color: #298580;
            font-size: 0.8666rem;
            text-decoration: underline;
            padding-left: 1.1em;
          }
          .product-page-actions-container > a:hover span {
            text-decoration: none;
          }


        .product-page-gallery-container {
          background: #fff;
          border: 3px solid #f6f6f6;
          padding: 10px;
        }
          .product-page-actions-container + .product-page-gallery-container {
            margin-top: -3px;
          }
          .product-page-gallery {
            height: 0px;
            padding-top: 100%;
            position: relative;
          }
            .product-page-gallery > div {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
            }
              .product-page-gallery .slick-track {
                height: 100%;
              }
                .product-page-gallery-image {
                  height: 100%;
                  position: relative;
                }
                  .product-page-gallery-image > div {
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    background-size: contain;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                  }
                    .product-page-gallery-image > div a {
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      right: 0px;
                      bottom: 0px;
                    }

            .product-page-gallery-pager {
              padding: 10px 0px 0px;
              position: relative;
              z-index: 1;
            }
              .product-page-gallery-pager ul {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: center;
                margin: 0px 0px 0px -8px;
              }
                .product-page-gallery-pager li {
                  width: 97px;
                  padding: 8px 0px 0px 8px;
                  cursor: pointer;
                }
                  .product-page-gallery-pager li div {
                    background: #ccc;
                    height: 6px;
                    transition: background .15s ease;
                    cursor: pointer;
                  }
                    .product-page-gallery-pager li:hover div {
                      background: #999;
                    }
                    .product-page-gallery-pager li.slick-active div {
                      background: #298580;
                    }


      .product-page-left + div {
        padding-left: 5%;
      }
      .product-page-info-wrapper {
        width: 100%;
      }
        .product-page-info-wrapper .product-page-params .subheader {
          font-size: 1rem;
          font-weight: 700;
          text-align: left;
          text-transform: uppercase;
          padding-top: 0px;
        }

        .product-page-info-wrapper .product-page-params table {
          width: 100%;
          border: none;
        }
          .product-page-info-wrapper .product-page-params table tr > th,
          .product-page-info-wrapper .product-page-params table tr > td {
            text-align: left;
            padding: 10px 0px;
            border-bottom: 1px solid #d9d9d9;
          }
          .product-page-info-wrapper .product-page-params table tr > th {
            font-weight: 600;
            width: 42%;
            padding-right: 15px;
          }

          .product-page-info-wrapper .product-page-params .product-discount-row td > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
            .product-page-info-wrapper .product-page-params .product-discount-btn {
              display: inline-block;
              background: url('/i/question-icon.svg') no-repeat 50% 50%/12px auto #298580;
              border-radius: 50%;
              width: 20px;
              min-width: 20px;
              height: 20px;
              margin-left: 7px;
              cursor: pointer;
            }
              .product-page-info-wrapper .product-page-params .product-discount-btn:hover {
                background-color: #167074;
              }

          .product-page-price-wrapper {
            margin-top: 25px;
          }
            .product-page-price-old {
              font-size: 1.2rem;
              padding: 0px 0px 4px;
              vertical-align: baseline;
            }
              .product-page-price-old * {
                font-size: 1.4rem;
                vertical-align: baseline;
              }

            .product-page-price {
              font-size: 1.6rem;
              font-weight: 600;
              vertical-align: baseline;
            }
              .product-page-price-old + .product-page-price {
                color: #f33c38;
              }
              .product-page-price * {
                font-size: 1.8rem;
                font-weight: 700;
                vertical-align: baseline;
              }

          .product-page-buttons {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 10px 0px 0px -15px;
          }
            .product-page-buttons > div {
              padding: 15px 0px 0px 15px;
            }
              .product-page-buttons .button-style {
                font-size: 1rem;
              }
              .product-page-buttons .callback-order {
                background: #f15a11;
              }
                .product-page-buttons .callback-order:hover {
                  background: #da5211;
                }

          .product-page-info-icons {
            margin: 20px 0px 0px;
          }
            .product-page-info-icons > div {
              vertical-align: middle;
              padding: 15px 0px 0px;
            }
              .product-page-info-icons img {
                vertical-align: middle;
                width: 24px;
                margin: 0px 10px 0px 0px;
              }


    .main-manager-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px 0px 20px;
    }
      .main-manager-block > div {
        flex-basis: 100%;
      }
      .main-manager-block > div + div {
        padding-left: 35px;
      }

      .callback-manager-block {
        flex-shrink: 4;
      }
        .callback-manager-block .list-view-item-wrapper {
          flex-direction: row;
          padding: 10px;
        }
          .callback-manager-block .list-view-item-wrapper > div {
            flex-basis: 100%;
          }
            .callback-manager-block .list-view-item-image {
              flex-shrink: 12;
              background: none;
            }
              .callback-manager-block .list-view-item-image a,
              .callback-manager-block .list-view-item-image span {
                background-position: 50% 0%;
                padding-top: 90%;
              }

            .callback-manager-block .list-view-item-text {
              flex-shrink: 9;
              justify-content: flex-start;
              height: auto;
              padding: 10px 0px 20px 35px;
            }
              .callback-manager-block .list-view-item-name a,
              .callback-manager-block .list-view-item-name span {
                font-size: 1.4rem;
              }
                .callback-manager-block .list-view-item-name a span,
                .callback-manager-block .list-view-item-name span span {
                  text-transform: uppercase;
                  font-weight: 700;
                }

              /*.callback-manager-block .list-view-item-notice * {
                font-size: 1.0666rem;
              }*/


/* Forms */
.form .agreement {
  color: #999;
}
  .form .agreement,
  .form .agreement * {
    font-size: 0.8666rem;
    line-height: 1.3;
    font-style: italic;
  }


/*.form-popup-container {
  background-color: transparent !important;
}*/
  .form-popup-container .subheader {
    color: #fff;
    background: #298580;
    font-family: "Open Sans", "Helvetica", "Tahoma", "Arial", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    border-radius: 3px 3px 0px 0px;
    padding: 18px 20px;
  }

  #callback-btn-content .form-block-container,
  #callback-msg-btn-content .form-block-container,
  #callback-otdelka-btn-content .form-block-container,
  #callback-order-btn-content .form-block-container,
  #fast-order-popup-basket .form-block-container {
    width: 400px;
  }
    .form-popup-container .form-container {
      background: #fff;
      padding: 20px;
    }
      .form-popup-container .form-buttons {
        text-align: center;
        margin-left: -13px;
      }
        .form-popup-container .form-button {
          margin-left: 13px;
        }

      .form-popup-container .agreement {
        text-align: center;
        padding: 15px 0px 0px;
      }


.main-manager-block .form-block-container {
  flex-shrink: 7;
}
  .main-manager-block .form-block-container .subheader {
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    padding: 0px;
  }
    .main-manager-block .form-container {
      padding: 10px 0px 0px;
    }
      .main-manager-block .form .form-bottom {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 30px 0px 0px -13px;
      }
        .main-manager-block .form .form-bottom > * {
          margin-top: 0px;
          margin-left: 13px;
        }
        .main-manager-block .form .agreement {
          margin-bottom: 5px;
        }



/* Contact */
.contact-page .subsubheader {
  font-size: 1.1333rem;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  padding: 0em 0em 1em;
}

.contact-page-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: -35px 0px 0px -50px;
}
  .contact-page-info > div {
    width: 50%;
    padding: 35px 0px 0px 50px;
  }

    .contact-icon-container > div + div {
      margin-top: 15px;
    }
      .contact-icon-container * {
        font-size: 1.0666rem;
      }

      .address-icon-container,
      .phones-icon-container,
      .worktime-icon-container,
      .emails-icon-container {
        padding-left: 30px;
        position: relative;
      }
        .address-icon-container:before,
        .phones-icon-container:before,
        .worktime-icon-container:before,
        .emails-icon-container:before {
          content: "";
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 4px;
          left: 0px;
          width: 18px;
          height: 16px;
        }
        .address-icon-container:before {
          background-image: url('/i/address-icon--gray.svg');
          height: 18px;
        }
        .phones-icon-container:before {
          background-image: url('/i/phone-icon--gray.svg');
        }
        .worktime-icon-container:before {
          background-image: url('/i/time-icon--gray.svg');
        }
        .emails-icon-container:before {
          background-image: url('/i/mail-icon--gray.svg');
        }

      .contact-icon-container .phones-icon-container > div + div {
        padding-top: 5px;
      }
        .contact-icon-container .phones-icon-container a {
          cursor: default;
        }
        .contact-icon-container .phones-icon-container span {
          color: #909090;
          font-size: 0.9333rem;
          vertical-align: baseline;
        }


    .contact-page-manager .list-view-item-wrapper {
      box-shadow: none;
      padding: 0px;
    }
      .contact-page-manager .list-view-item-text {
        flex-shrink: 8;
        padding: 0px 0px 10px 20px;
      }
        .contact-page-manager .list-view-item-notice {
          padding-top: 15px;
        }



/* Footer */
.footer-callback-msg-block {
  background: #298580;
  padding: 2.2em 0em;
}
  .footer-callback-msg-block > .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
    .footer-callback-msg-block .subheader {
      color: #fff;
      font-size: 2.3333rem;
      padding-top: 0em;
      text-align: left;
    }
    .footer-callback-msg-notice {
      color: #fff;
      font-size: 1.2rem;
    }

    .footer-callback-msg-block .button-style {
      border: 2px solid #fff;
    }


.footer {
  background: #43424e;
  padding: 0px;
  margin: 0px;
  position: relative;
  z-index: 1;
}
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 60px 0px;
    margin: 0px 0px 0px -20px;
  }
    .footer-content > div {
      flex: 1 100%;
      padding: 0px 0px 0px 20px;
    }

      .footer-menu-container li + li {
        margin-top: 14px;
      }
        .footer-menu-container li a,
        .footer-menu-container li span {
          color: #fff;
          font-size: 1.2rem;
          font-weight: 700;
          text-decoration: none;
          text-transform: uppercase;
          cursor: default;
        }
        .footer-menu-container li a {
          cursor: pointer;
        }
          .footer-menu-container li a:hover {
            text-decoration: underline;
          }


    .footer-info-container .info-header {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0em;
    }
      .footer-info-container .info-header ~ div {
        margin-top: 15px;
      }
        .footer-info-container .info-header ~ div * {
          color: #ccc;
        }

        .footer-info-container .phones-icon-container a {
          cursor: default;
        }
        .footer-info-container .phones-icon-container span {
          color: #999;
          font-size: 0.8333rem;
          vertical-align: baseline;
        }

        .footer-info-container .emails-icon-container a {
          cursor: pointer;
          text-decoration: underline;
        }
          .footer-info-container .emails-icon-container a:hover {
            text-decoration: none;
          }


      .footer-social-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: center;
        margin: -15px 0px 0px -15px;
      }
        .footer-social-container > div {
          padding: 15px 0px 0px 15px;
        }
          .footer-social-container a {
            content: "";
            display: block;
            color: transparent;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            border: 1px solid #fff;
            border-radius: 8px;
            font-size: 0px;
            width: 46px;
            height: 46px;
            cursor: pointer;
            position: relative;
          }
            .footer-social-container a:hover {
              background-color: #fff;
            }
              .footer-social-container .social-icon-vk a {
                background-image: url(/f/upload/social-icon-vk-footer-min.svg);
              }
                .footer-social-container .social-icon-vk a:hover {
                  background-image: url(/f/upload/social-icon-vk-footer-hover-min.svg);
                }
              .footer-social-container .social-icon-fb a {
                background-image: url(/f/upload/social-icon-fb-footer-min.svg);
              }
                .footer-social-container .social-icon-fb a:hover {
                  background-image: url(/f/upload/social-icon-fb-footer-hover-min.svg);
                }
              .footer-social-container .social-icon-instagram a {
                background-image: url(/f/upload/social-icon-instagram-footer-min.svg);
              }
                .footer-social-container .social-icon-instagram a:hover {
                  background-image: url(/f/upload/social-icon-instagram-footer-hover-min.svg);
                }
              .footer-social-container .social-icon-twitter a {
                background-image: url(/f/upload/social-icon-twitter-footer-min.svg);
              }
                .footer-social-container .social-icon-twitter a:hover {
                  background-image: url(/f/upload/social-icon-twitter-footer-hover-min.svg);
                }


  .footer-copyrights {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #52504b;
    padding: 30px 0px;
  }
    .footer-copyright {
      flex: 1 100%;
    }
      .footer-copyright * {
        color: #999 !important;
        font-size: 0.8666rem;
      }
        .footer-copyright a.link:hover {
          color: #fff !important;
        }
